import { ChannelType } from "../../models/entities/post";
import YouTubeChannel from "../../models/google/youtube-channel";
import MetaAccount from "../../models/meta/account";
import InstagramAccount from "../../models/meta/instagram-account";
import AccountPickerItem from "../../modules/social-sets/channels/models/account-picker-item";
import channelService from "../api/channel-service";
import facebookService from "../api/facebook-service";
import googleService from "../api/google-service";
import instagramService from "../api/instagram-service";
import pinterestService from "../api/pinterest-service";
import tiktokService from "../api/tiktok-service";
import twitterV1Service from "../api/twitter-v1-service";
import scopeValidatorService from "../application/scope-validator-service";
import sessionBrowserStorage from "../browser-storage/session-browser-storage";

export interface OAuthQueryParam {
  key: string;
  value: string;
}

class ChannelProviderService {
  getAuthorizationUrl(
    channelType: ChannelType,
    state: string
  ): Promise<string> {
    switch (channelType) {
      case "Facebook":
        return facebookService.getAuthorizationUrl(state);

      case "Instagram":
        return instagramService.getAuthorizationUrl(state);

      case "Pinterest":
        return pinterestService.getAuthorizationUrl(state);

      case "Twitter":
        return twitterV1Service.getAuthorizationUrl(state).then((data) => {
          sessionBrowserStorage.setItem("oauthCallbackState", state);
          return data;
        });

      case "YouTube":
        return googleService.getAuthorizationUrl(state);

      case "TikTok":
        return tiktokService.getAuthorizationUrl(state);

      default:
        return Promise.resolve("");
    }
  }

  async listChannels(
    channelType: ChannelType,
    extraParams: OAuthQueryParam[],
    fetchAvailabilities: boolean = true
  ): Promise<AccountPickerItem[]> {
    let accounts: AccountPickerItem[] = [];

    switch (channelType) {
      case "Facebook": {
        const code = extraParams.find((x) => x.key === "code")?.value;
        accounts = await this.listFacebookChannels(code);
        break;
      }

      case "Instagram": {
        const code = extraParams.find((x) => x.key === "code")?.value;
        accounts = await this.listInstagramChannels(code);
        break;
      }

      case "Pinterest": {
        const code = extraParams.find((x) => x.key === "code")?.value;
        accounts = await this.listPinterestChannels(code);
        break;
      }

      case "TikTok": {
        const code = extraParams.find((x) => x.key === "code")?.value;
        accounts = await this.listTikTokChannels(code);
        break;
      }

      case "Twitter": {
        const verifier = extraParams.find(
          (x) => x.key === "oauth_verifier"
        )?.value;
        const token = extraParams.find((x) => x.key === "oauth_token")?.value;

        accounts = await this.listTwitterChannels(token, verifier);
        break;
      }

      case "YouTube": {
        const code = extraParams.find((x) => x.key === "code")?.value;
        accounts = await this.listYouTubeChannels(code);
        break;
      }
    }

    if (fetchAvailabilities) {
      return await this.checkChannelsAvailability(channelType, accounts);
    }

    return accounts;
  }

  private async checkChannelsAvailability(
    channelType: ChannelType,
    accounts: AccountPickerItem[]
  ): Promise<AccountPickerItem[]> {
    const availabilities = await channelService.getAvailabilities(
      channelType,
      accounts.map((x) => x.id)
    );

    accounts.forEach((account) => {
      account.available = availabilities[account.id] ?? false;
    });

    return accounts;
  }

  private async listInstagramChannels(
    code: string
  ): Promise<AccountPickerItem[]> {
    // Exchange authorization code for access token
    const longAccessToken =
      await instagramService.exchangeCodeForLongLivedToken(code);

    // Get user Instagram pages from Meta API
    const accounts = await instagramService.getInstagramAccounts(
      "me",
      longAccessToken
    );

    return accounts.map(
      (account: InstagramAccount) =>
        ({
          id: account.id,
          name: `${account.username}`,
          username: account.username,
          subtitle: account.channelAccountType.label,
          picture: account.profile_picture_url,
          data: {
            accountType: account.channelAccountType,
            instagram: account,
          },
        } as AccountPickerItem)
    );
  }

  private async listFacebookChannels(
    code: string
  ): Promise<AccountPickerItem[]> {
    // Exchange authorization code for access token
    const longAccessToken = await facebookService.exchangeCodeForLongLivedToken(
      code
    );

    // Get user Facebook pages from Meta API
    const accounts = await facebookService.getFacebookAccounts(
      "me",
      longAccessToken
    );

    return accounts.map(
      (account: MetaAccount) =>
        ({
          id: account.id,
          name: account.name,
          subtitle: account.channelAccountType.label,
          picture: account.picture?.data?.url,
          data: {
            accountType: account.channelAccountType,
            facebook: account,
          },
        } as AccountPickerItem)
    );
  }

  private async listPinterestChannels(
    code: string
  ): Promise<AccountPickerItem[]> {
    const tokens = await pinterestService.exchangeAuthorizationCode(code);
    scopeValidatorService.validatePinterestScopes(tokens.scope.split(" "));

    const account = await pinterestService.getUserAccount(tokens.access_token);

    return [
      {
        id: account.id.toString(),
        name: account.business_name ?? account.username,
        username: account.username,
        subtitle: account.channelAccountType.label,
        picture: account.profile_image,
        data: {
          accountType: account.channelAccountType,
          pinterest: {
            account: account,
            tokens: tokens,
          },
        },
      } as AccountPickerItem,
    ];
  }

  private async listTwitterChannels(
    token: string,
    verifier: string
  ): Promise<AccountPickerItem[]> {
    /// OAuth1.1
    // const authResponse = await twitterApi.getOAuthToken(authenticationUrl);
    const oauthTokens = await twitterV1Service.exchangeTokens(token, verifier);
    const account = await twitterV1Service.getUserAccount(
      oauthTokens.accessToken,
      oauthTokens.accessTokenSecret
    );

    /// OAuth2.0
    // const authorizationUrl = await twitterService.getAuthorizationUrl();
    // const authorizationCode = await twitterApi.getAuthorizationCode(authorizationUrl);
    // const tokens = await twitterService.exchangeAuthorizationCode(authorizationCode);
    // const account = await twitterService.getUserAccount(tokens.access_token);

    return [
      {
        id: account.id.toString(),
        name: account.name,
        username: account.username,
        subtitle: account.channelAccountType.label,
        picture: account.profile_image_url,
        data: {
          accountType: account.channelAccountType,
          twitter: {
            account: account,
            tokens: null,
            oauth: oauthTokens,
          },
        },
      } as AccountPickerItem,
    ];
  }

  private async listYouTubeChannels(
    code: string
  ): Promise<AccountPickerItem[]> {
    const tokens = await googleService.exchangeAuthCodeForTokens(code);
    scopeValidatorService.validateGoogleScopes(tokens.scope.split(" "));

    const youTubeChannels = await googleService.getYouTubeChannels(tokens);

    return youTubeChannels.map(
      (account: YouTubeChannel) =>
        ({
          id: account.id,
          name: account.snippet.title,
          username: account.snippet.customUrl,
          subtitle: account.channelAccountType.label,
          picture: account.snippet.thumbnails.medium.url,
          data: {
            accountType: account.channelAccountType,
            youtube: {
              ...account,
              tokens,
            },
          },
        } as AccountPickerItem)
    );
  }

  private async listTikTokChannels(code: string): Promise<AccountPickerItem[]> {
    const tokens = await tiktokService.exchangeCodeForToken(code);
    scopeValidatorService.validateTikTokScopes(tokens.scope.split(","));

    const account = await tiktokService.getAccount(tokens.access_token);

    return [
      {
        id: account.union_id,
        name: account.display_name,
        username: account.username,
        subtitle: account.channelAccountType.label,
        picture: account.avatar_url,
        data: {
          accountType: account.channelAccountType,
          tiktok: {
            account,
            tokens,
          },
        },
      } as AccountPickerItem,
    ];
  }
}

export default new ChannelProviderService();
