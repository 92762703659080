import { useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../queries/query-names";
import HashtagList from "./components/HashtagList";
import hashtagService from "../../../services/api/hashtag-service";
import Hashtag from "../../../models/entities/hashtag";
import Spinner from "../../../components/common/Spinner";
import EmptyHashtagLists from "./components/EmptyHashtagLists";
import CreateHashtagListDialog from "./components/CreateHashtagListDialog";
import Button from "../../../components/common/Button";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";

interface HashtagsProps {
  isSelectionMode?: boolean;
  onSelected?: (hashtag: Hashtag) => void;
}

export default function Hashtags({
  isSelectionMode = false,
  onSelected,
}: HashtagsProps) {
  const createDialogRef = useRef(null);
  const currentSocialSet = useCurrentSocialSet();
  const [searchQuery, setSearchQuery] = useState<string>(null);
  const [selectedHashtag, setSelectedHashtag] = useState<Hashtag>(null);

  const {
    isLoading,
    data: hashtagsLists,
    refetch: refetchHashtags,
  } = useQuery<Hashtag[]>({
    queryKey: [queryNames.hashtagsBySocialSetId, currentSocialSet.id],
    queryFn: () => hashtagService.list(currentSocialSet.id),
  });

  const displayedHashtagLists = useMemo(() => {
    let result = hashtagsLists;

    if (searchQuery) {
      result = result.filter((slot) => {
        return (
          slot.hashtags?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          slot.topic?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }

    return result;
  }, [hashtagsLists, searchQuery]);

  const onEdit = (hashtag: Hashtag) => {
    if (isSelectionMode) {
      onSelected?.(hashtag);
      setSelectedHashtag(hashtag);
    } else {
      createDialogRef.current.openDialog({ hashtag: hashtag });
    }
  };

  const openCreatePopup = () => {
    createDialogRef.current.openDialog({ hashtag: null });
  };

  const onHashtagSubmitted = () => {
    refetchHashtags();
  };

  const onDeleted = () => {
    refetchHashtags();
  };

  return (
    <>
      {!isSelectionMode && (
        <header className="flex flex-none items-center justify-start gap-4 sticky top-0 z-10 ring-1 ring-black ring-opacity-5 px-6 py-4 bg-white rounded-t-md">
          <div className="flex justify-between items-center w-full">
            <div>
              <h1 className="text-lg font-semibold leading-7 text-gray-900 w-full">
                Hashtag Lists
              </h1>
              <p className="text-sm leading-6 text-gray-500">
                Create and manage your own hashtag lists by organizing your
                hashtags into named collections.
              </p>
            </div>
          </div>
        </header>
      )}

      <div className="flex-1 flex-col h-full px-6 py-4">
        {isLoading && (
          <div className="h-full flex-1 flex flex-col justify-center ">
            <Spinner />
          </div>
        )}

        {!isLoading && !hashtagsLists.length && (
          <EmptyHashtagLists onOpenCreatePopup={openCreatePopup} />
        )}

        {hashtagsLists?.length > 0 && (
          <div className="flex-1 h-full flex flex-col gap-6">
            <div className="flex gap-2 mt-2">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-400"
                  />
                </div>
                <input
                  type="search"
                  onInput={(e) =>
                    setSearchQuery((e.target as HTMLInputElement).value)
                  }
                  placeholder="Find your hashtags"
                  className="block w-full max-w-sm rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-200 focus:ring-gray-300 placeholder:text-gray-400 text-sm leading-6"
                />
              </div>

              <div className="ml-auto">
                <Button
                  className="hidden sm:block"
                  text="Create Hashtag List"
                  color="brand"
                  onClick={openCreatePopup}
                />
                <Button
                  className="sm:hidden"
                  text="Create"
                  color="brand"
                  onClick={openCreatePopup}
                />
              </div>
            </div>

            <div className="flex-1 h-full">
              <HashtagList
                selectedHashtag={selectedHashtag}
                hashtags={displayedHashtagLists ?? []}
                loading={isLoading}
                onEdit={onEdit}
              />
            </div>
          </div>
        )}
      </div>

      <CreateHashtagListDialog
        ref={createDialogRef}
        onHashtagSubmitted={onHashtagSubmitted}
        onDeleted={onDeleted}
      />
    </>
  );
}
