import Hashtag from "../../../../models/entities/hashtag";
import HashtagCard from "./HashtagCard";

export interface HashtagListProps {
  hashtags: Hashtag[];
  loading: boolean;
  selectedHashtag?: Hashtag;
  onEdit: (hashtag: Hashtag) => void;
}
export default function HashtagList({
  hashtags,
  loading,
  selectedHashtag,
  onEdit,
}: HashtagListProps) {
  return (
    <>
      {hashtags.length == 0 && !loading && (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="text-sm text-center leading-6 text-gray-600">
            <span className="font-medium text-base">No hashtags found.</span>{" "}
            <br /> Click on "Generate" to create a new one.
          </div>
        </div>
      )}
      {hashtags.length > 0 && (
        <div className="grid grid-cols-6 gap-6">
          {hashtags.map((hashtag) => (
            <div key={hashtag.id} className="col-span-6 md:col-span-3">
              <HashtagCard
                hashtag={hashtag}
                selected={selectedHashtag?.id == hashtag.id}
                onEdit={onEdit}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
}
