import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { PostStatus } from "../../../../../models/entities/post";
import clsx from "clsx";
import { splitPascalCase } from "../../../../../utils/shared-utils";

export interface PostStatusLabelProps {
  status: PostStatus;
}

export default function PostStatusLabel({ status }: PostStatusLabelProps) {
  // const isStatus = (statuses: PostStatus[]) : string => statuses.includes(status) ? ;

  return (
    <div
      className={clsx(
        "inline-flex items-center rounded-md gap-1 px-2 py-1 text-xs font-medium ring-1 ring-inset",
        (status === "ProcessingFailed" || status === "PublishFailed") &&
          "bg-red-200/50 text-red-700 ring-red-700/10",
        status === "Draft" &&
          "bg-orange-200/50 text-orange-700 ring-orange-700/10",
        status === "Published" &&
          "bg-emerald-200/50 text-emerald-700 ring-emerald-700/10",
        (status === "Scheduled" ||
          status === "Processed" ||
          status == "ProcessingEnqueued" ||
          status == "Processing" ||
          status == "PublishingEnqueued" ||
          status == "Publishing") &&
          "bg-indigo-200/50 text-indigo-700 ring-indigo-700/10"
      )}
    >
      {(status === "ProcessingFailed" || status === "PublishFailed") && (
        <ExclamationCircleIcon className="h-4 w-4 text-red-600" />
      )}
      {status === "Published" && (
        <CheckCircleIcon className="h-4 w-4 text-emerald-600" />
      )}
      {(status === "Scheduled" ||
        status === "Processed" ||
        status == "ProcessingEnqueued" ||
        status == "Processing" ||
        status == "PublishingEnqueued" ||
        status == "Publishing") && (
        <ClockIcon className="h-4 w-4 text-indigo-600" />
      )}

      <div className="truncate">{splitPascalCase(status)}</div>
    </div>
  );
}
