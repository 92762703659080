/* eslint-disable @typescript-eslint/no-explicit-any */
import { SparklesIcon } from "@heroicons/react/20/solid";
import TextAreaField, { TextAreaFieldProps } from "../form/TextAreaField";
import Emojis from "../../modules/scheduler/create-post/components/Emojis";
import { useMemo } from "react";
import eventBusService from "../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../services/application/event-bus/event-bus-events";
import Hashtag from "../../models/entities/hashtag";
import HashtagsPicker from "../../modules/scheduler/create-post/components/HashtagsPicker";

export interface PrimaryTextBoxProps {
  textAreaOptions: TextAreaFieldProps;
  onTextChanged: (value: string) => void;
}

export default function PrimaryTextBox({
  textAreaOptions,
  onTextChanged,
}: PrimaryTextBoxProps) {
  const fieldKey = textAreaOptions.name;
  const formHook = textAreaOptions.formHook;

  const id = useMemo(() => `textbox-${fieldKey}`, [fieldKey]);

  const onEmojiClick = (emojiObject) => {
    const textAreaElement = document.getElementById(id) as any;
    let textAreaValue = formHook.getValues()[fieldKey];
    const selection = textAreaElement.selectionEnd;

    textAreaValue = `${textAreaValue.substring(
      0,
      textAreaElement.selectionStart
    )}${emojiObject.emoji} ${textAreaValue.substring(
      textAreaElement.selectionEnd
    )}`;

    formHook.setValue(fieldKey, textAreaValue);
    textAreaElement.focus();
    textAreaElement.selectionEnd = selection + 3;

    onTextChanged(textAreaValue);
  };

  const openAiAssistant = () => {
    eventBusService.dispatch(EventBusEvents.OPEN_AI_ASSISTANT);
    setTimeout(() => {
      eventBusService.dispatch(EventBusEvents.UPDATE_AI_ASSISTANT_CONTENT, {
        content: formHook.getValues()[fieldKey],
      });
    });
  };

  const onChange = (content?: string) => {
    eventBusService.dispatch(EventBusEvents.UPDATE_AI_ASSISTANT_CONTENT, {
      content: content,
    });

    onTextChanged(content);
  };

  const onHashtagsSelected = (hashtag: Hashtag) => {
    const textAreaElement = document.getElementById(id) as any;
    let textAreaValue = formHook.getValues()[fieldKey];
    const selection = textAreaElement.selectionEnd;
    const hashtagsContent = hashtag.hashtags;

    textAreaValue = `${textAreaValue}\n\n${hashtagsContent}`;

    formHook.setValue(fieldKey, textAreaValue);
    textAreaElement.focus();
    textAreaElement.selectionEnd = selection + 3;

    onTextChanged(textAreaValue);
  };

  return (
    <div className="flex flex-row relative">
      <div className="min-w-0 flex-1">
        <div className="overflow-hidden">
          <TextAreaField
            className={textAreaOptions.className}
            id={id}
            label={textAreaOptions.label}
            placeholder={textAreaOptions.placeholder}
            name={fieldKey}
            formHook={formHook}
            rows={textAreaOptions.rows}
            maxLength={textAreaOptions.maxLength}
            hideLabel={textAreaOptions.hideLabel}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>

        <div className="inset-x-0 flex justify-between py-2">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              {/* <SparklesIcon className="w-5 h-5 text-indigo-500" />
        <div className="text-lg font-semibold leading-6 inline-block text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
          AI Assistant
        </div> */}

              <span
                onClick={openAiAssistant}
                className="inline-flex items-center gap-2 rounded-md bg-primary-50 hover:bg-primary-200 px-2 py-1 text-xs cursor-pointer font-medium text-primary-700 ring-1 ring-inset ring-primary-700/10"
              >
                <SparklesIcon className="w-4 h-4" />
                <span className="inline-block text-xs font-medium">
                  AI Caption Generator
                </span>
              </span>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <HashtagsPicker onSelected={onHashtagsSelected} />
            <Emojis onEmojiClick={onEmojiClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
