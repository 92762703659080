/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogTitle } from "@headlessui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import Channel from "../../../models/entities/channel";
import Post, { PostAttachment } from "../../../models/entities/post";
import ScheduleButtons from "./components/ScheduleButtons";
import PostPreview from "./components/PostPreview";
import AttachmentSlot from "./models/attachment-slot";
import { PostInstance } from "./models/post-instance";
import postService from "../../../services/api/post-service";
import dayjs from "dayjs";
import eventBusService from "../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../services/application/event-bus/event-bus-events";
import createPostHelper, {
  AttachmentsChangedData,
} from "../../../services/application/create-post-helper";
import AiAssistant from "./components/ai-assistant/AiAssistant";
import ChannelsSelector2 from "./components/ChannelsSelector2";
import PostContainer from "./components/PostContainer";
import ChannelsSidenav from "./components/ChannelsSidenav";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import {
  EyeIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import PostsQuota from "./components/PostsQuota";
import queryNames from "../../../queries/query-names";
import { useQuery } from "@tanstack/react-query";
import planQuotaService from "../../../services/application/plan-quota-service";
import { Link } from "react-router-dom";
import quotaService from "../../../services/api/quota-service";
import TwitterQuota from "./components/TwitterQuota";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import { ScheduleAction } from "../../../models/api-requests/create-post-request";

interface CreatePostProps {
  onPostSubmitted: (posts: Post[]) => void;
  onClose: () => void;
  post?: Post;
  clonedPost?: Post;
  preselectedChannelIds?: string[];
  dateTime?: Date;
}

export default function CreatePost({
  post,
  clonedPost,
  dateTime,
  preselectedChannelIds,
  onPostSubmitted,
  onClose,
}: CreatePostProps) {
  const [selectedDateTime, setSelectedDateTime] = useState<Date>(null);
  const [postInstanceMap, setPostInstanceMap] = useState<
    Record<string, PostInstance>
  >({});
  const [attachmentMap, setAttachmentSlotMap] = useState<
    Record<string, AttachmentSlot>
  >({});
  const [currentPostInstanceId, setCurrentPostInstanceId] =
    useState<string>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState<boolean>(false);
  const [aiGenerator, setAiGenerator] = useState<boolean>(false);
  const [previewOn, setPreviewOn] = useState<boolean>(false);
  const [twitterQuota, setTwitterQuota] = useState<number>(null);
  const [twitterQuotaLoading, setTwitterQuotaLoading] =
    useState<boolean>(false);
  const { state } = useContext(GlobalStateContext);
  const { isMd } = useBreakpoint("md");
  const { isLg } = useBreakpoint("lg");

  const socialSet = useCurrentSocialSet();

  const { data: postQuota } = useQuery({
    queryKey: [queryNames.postQuotas],
    queryFn: () => quotaService.getPostsQuota(socialSet.timezone),
    staleTime: Infinity,
  });

  const { remainingUsage } = planQuotaService.calculatePostQuotaInfo(
    state.currentTenant.subscription,
    postQuota
  );

  const { remainingUsage: twitterRemainingUsage } =
    planQuotaService.calculateTwitterPostQuotaInfo(
      state.currentTenant.subscription,
      selectedDateTime,
      twitterQuota
    );

  const isEdit = !!post;
  const isClone = !!clonedPost;
  const currentSocialSet = state.currentSocialSet;

  const channels = useMemo(
    () =>
      createPostHelper.getSelectedChannels(
        [
          ...(preselectedChannelIds ?? []),
          post?.channel?.id,
          clonedPost?.channel?.id,
        ].filter((x) => !!x),
        currentSocialSet
      ),
    [currentSocialSet, post, clonedPost, preselectedChannelIds]
  );

  const currentPostInstance = useMemo(
    () =>
      currentPostInstanceId ? postInstanceMap[currentPostInstanceId] : null,
    [currentPostInstanceId, postInstanceMap]
  );

  const postInstances = useMemo(() => {
    return createPostHelper.getPostInstanceList(postInstanceMap, channels);
  }, [channels, postInstanceMap]);

  // Connect AI assistant
  useEffect(() => {
    const openAiAssistant = () => {
      setAiGenerator(true);
    };

    eventBusService.on(EventBusEvents.OPEN_AI_ASSISTANT, openAiAssistant);

    return () => {
      eventBusService.remove(EventBusEvents.OPEN_AI_ASSISTANT, openAiAssistant);
    };
  }, [currentPostInstanceId]);

  // Generate PostInstance and AttachmentSlot maps on initial load
  useEffect(() => {
    const generateAttachmentsMap = async (): Promise<
      Record<string, AttachmentSlot>
    > => {
      const attachments = (
        (post?.postAttachments ??
          clonedPost?.postAttachments ??
          []) as PostAttachment[]
      ).map((x) => x.attachment);

      const slotPromises = attachments.map((attachment) =>
        createPostHelper.getSlotFromAttachment(attachment)
      );

      const newSlots = await Promise.all(slotPromises);
      const newAttachmentMap = getUpdatedAttachmentSlots(
        {},
        {
          operation: "Add",
          slots: newSlots,
        }
      );

      setAttachmentSlotMap(newAttachmentMap);

      return newAttachmentMap;
    };

    const process = async () => {
      const newAttachmentMap = await generateAttachmentsMap();
      await generatePostInstanceMap(channels, newAttachmentMap);
    };

    process();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels, post, clonedPost]);

  // Calculate scheduled date
  useEffect(() => {
    const date = post?.scheduledAt
      ? dayjs(post?.scheduledAt).tz(currentSocialSet?.timezone).toDate()
      : dateTime
      ? dayjs(dateTime).tz(currentSocialSet?.timezone).toDate()
      : dayjs()
          .tz(currentSocialSet?.timezone)
          .add(1, "day")
          .hour(12)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate();

    setSelectedDateTime(date);
  }, [post?.scheduledAt, dateTime, currentSocialSet?.timezone]);

  // On breakpoint change: hide preview if screen is smaller than md, show it otherwise
  useEffect(() => {
    setPreviewOn(isMd);
  }, [isMd]);

  // Reload Twitter post quota
  useEffect(() => {
    const process = async () => {
      if (
        currentSocialSet.channels.some((x) => x.type == "Twitter") &&
        selectedDateTime
      ) {
        try {
          setTwitterQuotaLoading(true);
          const quota = await quotaService.getTwitterPostQuota(
            selectedDateTime,
            socialSet.timezone
          );
          setTwitterQuota(quota);
        } finally {
          setTwitterQuotaLoading(false);
        }
      }
    };

    process();
  }, [currentSocialSet.channels, selectedDateTime, socialSet.timezone]);

  // Disable Twitter channels if remaining usage for the selected date is less than 1
  useEffect(() => {
    if (twitterRemainingUsage < 1) {
      onChannelsUpdated(channels.filter((x) => x.type != "Twitter"));
    }
  }, [twitterRemainingUsage]);

  const generatePostInstanceMap = async (
    channels: Channel[] = [],
    attachmentMap: Record<string, AttachmentSlot>
  ): Promise<{
    updatedPostInstanceMap: Record<string, PostInstance>;
    updatedCurrentPostInstance?: PostInstance;
  }> => {
    const updatedInstanceMap = await createPostHelper.getUpdatedPostInstanceMap(
      postInstanceMap,
      attachmentMap,
      post,
      clonedPost,
      channels
    );

    setPostInstanceMap(() => {
      return {
        ...updatedInstanceMap,
      };
    });

    const oldChannelIds = Object.keys(postInstanceMap);
    const currentChannelIds = channels?.map((x) => x.id) ?? [];
    const addedChannelIds = currentChannelIds.filter(
      (x) => !oldChannelIds.includes(x)
    );

    if (addedChannelIds.length) {
      addedChannelIds.forEach((channelId) => {
        tryPopulatePostInstance(updatedInstanceMap[channelId]);
      });
    }

    let nextOpenInstance = currentPostInstance;
    if (!currentPostInstanceId || !updatedInstanceMap[currentPostInstanceId]) {
      nextOpenInstance = createPostHelper.getNextPostInstance(
        updatedInstanceMap,
        currentPostInstanceId
      );

      setCurrentPostInstanceId(nextOpenInstance?.channel?.id);
    }

    return {
      updatedCurrentPostInstance: nextOpenInstance,
      updatedPostInstanceMap: updatedInstanceMap,
    };
  };

  const onSubmit = async (scheduleAction: ScheduleAction) => {
    const submitPost = (postInstance: PostInstance): Promise<Post> => {
      return postInstance.post
        ? postService.update(postInstance.post.id, {
            title: postInstance.title,
            channelId: postInstance.channel.id,
            config: postInstance.postConfig,
            postAttachments: postInstance.attachmentSlotIds.map(
              (attachmentSlotId) => ({
                id: "",
                attachmentId: attachmentMap[attachmentSlotId].attachment.id,
              })
            ),
            scheduledAt: selectedDateTime,
            scheduleAction: scheduleAction,
          })
        : postService.create({
            title: postInstance.title,
            channelId: postInstance.channel.id,
            config: postInstance.postConfig,
            postAttachments: postInstance.attachmentSlotIds.map(
              (attachmentSlotId) => ({
                id: "",
                attachmentId: attachmentMap[attachmentSlotId].attachment.id,
              })
            ),
            scheduledAt: selectedDateTime,
            scheduleAction: scheduleAction,
          });
    };

    try {
      setIsSubmitting(true);

      const postPromises = postInstances.map(submitPost);
      const posts = await Promise.allSettled(postPromises);

      const failed = posts.filter((x) => x.status == "rejected");
      const successful = posts
        .filter((x) => x.status == "fulfilled")
        .map((x) => (x as any).value as Post);

      if (!failed.length) {
        onPostSubmitted(successful);
      } else {
        const successfulIds = successful.map((x) => x.channel.id);

        setPostInstanceMap((prev) => {
          const updatedPostInstanceMap = {
            ...prev,
          };
          successfulIds.forEach((channelId) => {
            delete updatedPostInstanceMap[channelId];
          });

          return updatedPostInstanceMap;
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChannelsUpdated = (channels: Channel[]) => {
    generatePostInstanceMap(channels, attachmentMap);
  };

  const onDateTimeChanged = (date: Date) => {
    setSelectedDateTime(date);
  };

  const onSubmitAttempted = () => {
    setIsSubmitAttempted(true);
  };

  const tryPopulateUntouched = (
    postInstanceMap: Record<string, PostInstance>,
    sourcePostInstance: PostInstance
  ) => {
    const postInstances = Object.values(postInstanceMap);
    const untouchedPostInstances = postInstances.filter((x) => !x.touched);

    if (untouchedPostInstances.length) {
      // const sourcePostInstance =
      //   postInstances.find((x) => x.title && x.attachmentSlotIds.length) ??
      //   postInstances.find((x) => x.attachmentSlotIds.length) ??
      //   postInstances.find((x) => x.title) ??
      //   currentPostInstance;

      if (sourcePostInstance) {
        console.log(
          `Copy from ${
            sourcePostInstance.channel.type
          } to ${untouchedPostInstances.map((x) => x.channel.type).join(", ")}`
        );
        const updatedPostInstanceMap = {
          ...postInstanceMap,
        };

        untouchedPostInstances
          .map((x) => clonePostInstance(sourcePostInstance, x))
          .filter((x) => !!x)
          .forEach((x) => {
            updatedPostInstanceMap[x.channel.id] = x;
          });

        setPostInstanceMap(updatedPostInstanceMap);
      }
    }
  };

  const tryPopulatePostInstance = (postInstance: PostInstance) => {
    if (!postInstance) {
      return;
    }

    if (!postInstance.touched) {
      const sourcePostInstance =
        postInstances.find((x) => x.title && x.attachmentSlotIds.length) ??
        postInstances.find((x) => x.attachmentSlotIds.length) ??
        postInstances.find((x) => x.title);

      if (sourcePostInstance) {
        const updatedPostInstance = clonePostInstance(
          sourcePostInstance,
          postInstance
        );

        setPostInstanceMap((prev) => {
          return {
            ...prev,
            [updatedPostInstance.channel.id]: updatedPostInstance,
          };
        });
      }
    }
  };

  const onPostInstanceChanged = (
    postInstance: PostInstance,
    touched: boolean = false
  ) => {
    setPostInstanceMap((prev) => {
      const updatedPostInstance: PostInstance = {
        ...prev[postInstance.channel.id],
        touched: prev[postInstance.channel.id].touched == true ? true : touched,
        title: postInstance.title,
        postConfig: {
          ...postInstance.postConfig,
        },
      };

      updatedPostInstance.validation = createPostHelper.validatePostInstance(
        updatedPostInstance,
        attachmentMap
      );

      const updatedPostInstanceMap = {
        ...prev,
        [postInstance.channel.id]: updatedPostInstance,
      };

      tryPopulateUntouched(updatedPostInstanceMap, updatedPostInstance);

      return updatedPostInstanceMap;
    });
  };

  const getUpdatedAttachmentSlots = (
    currentAttachmentSlotsMap: Record<string, AttachmentSlot>,
    data: AttachmentsChangedData
  ): Record<string, AttachmentSlot> => {
    const updated = { ...currentAttachmentSlotsMap };

    switch (data.operation) {
      case "Add":
        data.slots.forEach((slot) => {
          updated[slot.id] = slot;
        });
        break;

      case "Delete":
        // Delete attachment slots that are not used by any post instance
        // data.slots
        //   .filter(
        //     (x) =>
        //       !Object.values(currentPostInstanceMap).some((y) =>
        //         y.attachmentSlotIds.includes(x.id)
        //       )
        //   )
        //   .forEach((slot) => {
        //     delete updated[slot.id];
        //   });
        break;

      case "Update":
        data.slots.forEach((slot) => {
          updated[slot.id] = slot;
        });
        break;
    }

    return updated;
  };

  const getUpdatedAttachmentSlotIds = (
    currentPostInstanceMap: Record<string, PostInstance>,
    postInstance: PostInstance,
    data: AttachmentsChangedData
  ): string[] => {
    switch (data.operation) {
      case "Add":
        return onFilesAttached(
          currentPostInstanceMap,
          postInstance,
          data.slots
        );

      case "Delete":
        return onSlotsDeleted(currentPostInstanceMap, postInstance, data.slots);

      case "Reorder":
        return onSlotsReorder(currentPostInstanceMap, postInstance, data.slots);

      default:
        return (
          currentPostInstanceMap[postInstance.channel.id]?.attachmentSlotIds ??
          []
        );
    }
  };

  const onAttachmentsChanged = (
    postInstance: PostInstance,
    data: AttachmentsChangedData
  ) => {
    setAttachmentSlotMap((currentAttachmentsMap) => {
      const updatedAttachmentsMap = getUpdatedAttachmentSlots(
        currentAttachmentsMap,
        data
      );

      setPostInstanceMap((currentPostInstanceMap) => {
        const updatedSlotIds = getUpdatedAttachmentSlotIds(
          currentPostInstanceMap,
          postInstance,
          data
        );

        const updatedPostInstance: PostInstance = {
          ...currentPostInstanceMap[postInstance.channel.id],
          attachmentSlotIds: updatedSlotIds,
          touched: true,
        };

        const validationResult = createPostHelper.validatePostInstance(
          updatedPostInstance,
          attachmentMap
        );

        updatedPostInstance.validation = validationResult;

        const updatedPostInstanceMap = {
          ...currentPostInstanceMap,
          [postInstance.channel.id]: updatedPostInstance,
        };

        tryPopulateUntouched(updatedPostInstanceMap, updatedPostInstance);

        return updatedPostInstanceMap;
      });

      return updatedAttachmentsMap;
    });
  };

  const onFilesAttached = (
    currentMap: Record<string, PostInstance>,
    postInstance: PostInstance,
    slots: AttachmentSlot[]
  ): string[] => {
    const currentSlots = currentMap[postInstance.channel.id].attachmentSlotIds;
    const updatedSlots = [...currentSlots, ...slots.map((x) => x.id)];
    return updatedSlots;
  };

  const onSlotsDeleted = (
    currentMap: Record<string, PostInstance>,
    postInstance: PostInstance,
    slots: AttachmentSlot[]
  ): string[] => {
    const deletedSlotIds = slots.map((x) => x.id);
    const currentSlots = currentMap[postInstance.channel.id].attachmentSlotIds;
    const updatedSlots = [...currentSlots].filter(
      (x) => !deletedSlotIds.includes(x)
    );

    return updatedSlots;
  };

  const onSlotsReorder = (
    currentMap: Record<string, PostInstance>,
    postInstance: PostInstance,
    slots: AttachmentSlot[]
  ): string[] => {
    if (slots.length !== 2) {
      return;
    }

    const currentSlots = currentMap[postInstance.channel.id].attachmentSlotIds;
    const updatedSlots = [...currentSlots];

    const active = slots[0].id;
    const over = slots[1].id;

    const oldIndex = currentSlots.findIndex((x) => x == active);
    const newIndex = currentSlots.findIndex((x) => x == over);

    updatedSlots.splice(newIndex, 0, updatedSlots.splice(oldIndex, 1)[0]);

    return updatedSlots;
  };

  const clonePostInstance = (
    sourcePostInstance: PostInstance,
    destinationPostInstance: PostInstance
  ): PostInstance => {
    return createPostHelper.clonePostInstance(
      attachmentMap,
      sourcePostInstance,
      destinationPostInstance
    );
  };

  const openPostInstance = (postInstance: PostInstance) => {
    if (currentPostInstanceId == postInstance.channel.id) {
      return;
    }

    setCurrentPostInstanceId(postInstance.channel.id);
  };

  const publishNow = (): Promise<void> => {
    return onSubmit("PublishNow");
  };

  const schedulePost = (): Promise<void> => {
    return onSubmit("Schedule");
  };

  const saveDraft = (): Promise<void> => {
    return onSubmit("SaveDraft");
  };

  const scheduleNext = (): Promise<void> => {
    return onSubmit("ScheduleNext");
  };

  const addToQueue = (): Promise<void> => {
    return onSubmit("AddToQueue");
  };

  const togglePreview = () => {
    setPreviewOn(!previewOn);
  };

  return (
    <>
      <div className="flex flex-row justify-center gap-0 lg:gap-3 relative mx-auto h-dvh lg:h-[85vh] lg:max-h-[846px]">
        {/* AI Generator Container */}
        {aiGenerator && (
          <div
            className={clsx(
              "overflow-hidden bg-white rounded-lg shadow-xl transition-all w-full md:w-[20.5rem] absolute top-0 right-0 left-0 bottom-0 z-40",
              previewOn ? "xl:relative" : "lg:relative"
            )}
          >
            <AiAssistant
              channelType={currentPostInstance?.channel?.type}
              onClose={() => setAiGenerator(false)}
            />
          </div>
        )}

        {/* Configuration Container */}
        <div className="w-screen lg:max-w-3xl relative flex-1 flex flex-col overflow-hidden bg-white lg:rounded-lg p-3 md:px-6 md:py-4 shadow-xl transition-all">
          {/* Title bar */}
          <DialogTitle
            as="h3"
            className="flex flex-col justify-start mb-1 text-lg font-semibold leading-6 text-gray-900"
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                {!isEdit
                  ? !isClone
                    ? "Create Post"
                    : "Duplicate Post"
                  : "Edit Post"}
                {post?.id && (
                  <span className="text-xs text-stone-400 font-light">
                    ({post.id})
                  </span>
                )}
              </div>
              <div className="flex gap-2">
                {((isEdit && currentPostInstance?.channel?.type == "Twitter") ||
                  (!isEdit &&
                    currentSocialSet?.channels?.some(
                      (x) => x.type == "Twitter"
                    ))) && (
                  <div>
                    <TwitterQuota
                      postCount={twitterQuota}
                      date={selectedDateTime}
                      loading={twitterQuotaLoading}
                    />
                  </div>
                )}
                <div>
                  <PostsQuota />
                </div>
                <div className="block md:hidden">
                  <EyeIcon
                    onClick={togglePreview}
                    className="w-7 h-7 bg-white rounded-full text-gray-700 shadow button-shadow p-0.5"
                  />
                </div>
                <div className="block lg:hidden">
                  <XMarkIcon
                    onClick={onClose}
                    className="w-7 h-7 bg-white rounded-full text-gray-800 shadow button-shadow p-0.5"
                  />
                </div>
              </div>
            </div>
            {!isEdit ? (
              <div className="flex-1 flex items-center overflow-x-auto mt-3 pb-2 ">
                <ChannelsSelector2
                  channels={currentSocialSet?.channels ?? []}
                  postInstanceMap={postInstanceMap}
                  postInstances={postInstances}
                  isTwitterEnabled={twitterRemainingUsage > 0}
                  onSelected={onChannelsUpdated}
                />
              </div>
            ) : null}
          </DialogTitle>

          {/* Main container */}
          {remainingUsage == 0 ? (
            <div className="-mt-20 flex flex-col items-center justify-center w-full max-w-md mx-auto text-center h-full">
              <img src="/logo/viraly-logo-square-dense.png" className="w-12" />
              <p className="mt-2 text-xl font-bold leading-8 tracking-tight text-gray-800">
                It's time for an upgrade!
              </p>
              <p className="mt-6 text-base leading-1 text-gray-600">
                You've reached your current monthly limit for posting. To
                increase the limit upgrade your plan.
              </p>
              <div className="mt-10 flex justify-center">
                <Link
                  to="/settings/plan"
                  className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  Upgrade your plan <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          ) : (
            <>
              {!postInstances.length ? (
                <div className="rounded-md bg-blue-50 p-4 mt-2.5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-blue-400"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-blue-700">
                        Please select at least one channel to continue.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="flex flex-1"
                    style={{ height: "calc(100% - 9.5rem)" }}
                  >
                    <div>
                      <ChannelsSidenav
                        postInstances={postInstances}
                        selected={currentPostInstance}
                        hideValidation={!isSubmitAttempted}
                        onSelected={openPostInstance}
                      />
                    </div>
                    <div className="flex-1 flex flex-col gap-2 mt-2">
                      <div className="flex gap-2 w-full flex-1  shadow post-container-shadow rounded-lg overflow-y-auto">
                        {/* Main Content */}
                        {postInstances.map((postInstance) => (
                          <PostContainer
                            key={postInstance.channel.id}
                            isCurrent={
                              currentPostInstanceId == postInstance.channel.id
                            }
                            postInstance={
                              postInstanceMap[postInstance.channel.id]
                            }
                            attachments={createPostHelper.getPostAttachments(
                              postInstance,
                              attachmentMap
                            )}
                            isLg={isLg}
                            onPostInstanceChanged={onPostInstanceChanged}
                            onAttachmentsChanged={onAttachmentsChanged}
                          />
                        ))}
                      </div>
                      <div className="z-30 flex justify-between pt-1 md:pt-3">
                        <ScheduleButtons
                          loading={isSubmitting}
                          dateTime={selectedDateTime}
                          onDateTimeChanged={onDateTimeChanged}
                          isEdit={!!post}
                          postInstances={postInstances}
                          onPublishNow={publishNow}
                          onSaveDraft={saveDraft}
                          onSchedulePost={schedulePost}
                          onAddToQueue={addToQueue}
                          onScheduleNext={scheduleNext}
                          onSubmitAttempted={onSubmitAttempted}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {/* Preview Container */}
        <div
          className={clsx(
            "overflow-hidden bg-white lg:rounded-lg shadow-xl transition-all w-full md:w-[20.5rem]",
            "absolute top-0 right-0 left-0 bottom-0 z-10 md:relative",
            previewOn ? "block" : "hidden"
          )}
        >
          <PostPreview
            postInstance={currentPostInstance}
            attachments={createPostHelper.getPostAttachments(
              currentPostInstance,
              attachmentMap
            )}
            togglePreview={togglePreview}
          />
        </div>
      </div>
    </>
  );
}
