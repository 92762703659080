const ChannelAccountTypes = {
  FacebookPage: {
    key: "FacebookPage",
    label: "Facebook Page",
  },
  InstagramBusiness: {
    key: "InstagramBusiness",
    label: "Instagram Business",
  },
  InstagramCreator: {
    key: "InstagramCreator",
    label: "Instagram Creator",
  },
  PinterestProfile: {
    key: "PinterestProfile",
    label: "Pinterest Profile",
  },
  PinterestBusiness: {
    key: "PinterestBusiness",
    label: "Pinterest Business",
  },
  TwitterProfile: {
    key: "TwitterProfile",
    label: "X Profile",
  },
  YouTubeChannel: {
    key: "YouTubeChannel",
    label: "YouTube Channel",
  },
  TikTokAccount: {
    key: "TikTokAccount",
    label: "TikTok Account",
  },
};

export default ChannelAccountTypes;
