import Attachment, { AttachmentType } from "../../models/entities/attachment";
import Channel from "../../models/entities/channel";
import Post, { ChannelType, PostConfig } from "../../models/entities/post";
import SocialSet from "../../models/entities/social-set";
import AttachmentSlot from "../../modules/scheduler/create-post/models/attachment-slot";
import { PostInstance } from "../../modules/scheduler/create-post/models/post-instance";
import { v4 as uuidv4 } from "uuid";
import {
  getDefaultPostConfig,
  validateFacebookPost,
  validateInstagramPost,
  validatePinterestPost,
  validateTikTokPost,
  validateTwitterPost,
  validateYouTubePost,
} from "../../utils/post-config-utils";
import { ValidationResult } from "../../modules/scheduler/create-post/models/validation-result";
import attachmentService from "../api/attachment-service";

export interface AttachmentsChangedData {
  operation: "Add" | "Update" | "Delete" | "Reorder";
  slots: AttachmentSlot[];
}

type AttachmentFileType =
  | "image/jpg"
  | "image/jpeg"
  | "image/png"
  | "image/gif"
  | "image/webp"
  | "video/mp4"
  | "video/webm"
  | "video/quicktime";

const imageAttachmentType: AttachmentFileType[] = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
];
const videoAttachmentTypes: AttachmentFileType[] = [
  "video/mp4",
  "video/quicktime",
];
const imageExceptGiftAttachmentTypes: AttachmentFileType[] = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];

class CreatePostHelper {
  getAttachmentFileType(
    postInstance: PostInstance,
    attachments: AttachmentSlot[]
  ): AttachmentFileType[] {
    switch (postInstance.postConfig?.channelType) {
      case "Facebook": {
        const postType =
          postInstance.postConfig.facebook.contentOptions.postType;

        switch (postType) {
          case "Reel":
            return videoAttachmentTypes;

          default:
            return [...imageAttachmentType, ...videoAttachmentTypes];
        }
      }

      case "Instagram": {
        const postType =
          postInstance.postConfig.instagram.contentOptions.postType;

        switch (postType) {
          case "Post":
            return [...imageExceptGiftAttachmentTypes, ...videoAttachmentTypes];

          case "Reel":
            return videoAttachmentTypes;
          default:
            return [...imageAttachmentType, ...videoAttachmentTypes];
        }
      }

      case "Pinterest": {
        const postType =
          postInstance.postConfig.pinterest.contentOptions.systemPostType;

        const defaultTypes = [...imageAttachmentType, ...videoAttachmentTypes];

        if (!attachments.length) {
          return defaultTypes;
        }

        switch (postType) {
          case "ImagePin":
          case "Carousel":
            return imageAttachmentType;

          case "VideoPin":
            return videoAttachmentTypes;

          default:
            return defaultTypes;
        }
      }

      case "Twitter": {
        const systemPostType =
          postInstance.postConfig.twitter.contentOptions.systemPostType;

        switch (systemPostType) {
          case "TextPost":
            return [];
          case "Carousel":
            return imageExceptGiftAttachmentTypes;

          default:
            return [...imageAttachmentType, ...videoAttachmentTypes];
        }
      }

      case "YouTube": {
        const postType =
          postInstance.postConfig.youTube.contentOptions.systemPostType;

        switch (postType) {
          case "ShortVideo":
            return videoAttachmentTypes;

          default:
            return [...imageAttachmentType, ...videoAttachmentTypes];
        }
      }

      case "TikTok": {
        const postType =
          postInstance.postConfig.tikTok.contentOptions.systemPostType;

        switch (postType) {
          case "Photo":
            return ["image/jpg", "image/jpeg", "image/webp"];

          case "Video":
            return ["video/mp4", "video/quicktime", "video/webm"];

          default:
            return [];
        }
      }

      default:
        return [...imageAttachmentType, ...videoAttachmentTypes];
    }
  }

  getPostInstanceList(
    postInstanceMap: Record<string, PostInstance>,
    channels: Channel[]
  ): PostInstance[] {
    const postInstances = Object.keys(postInstanceMap).map<PostInstance>(
      (x) => postInstanceMap[x]
    );

    postInstances.sort((x, y) => {
      return (
        channels.findIndex((c) => c.id == x.channel.id) -
        channels.findIndex((c) => c.id == y.channel.id)
      );
    });

    return postInstances;
  }

  getSelectedChannels(
    // post?: Post,
    preselectedChannelIds?: string[],
    currentSocialSet?: SocialSet
  ): Channel[] {
    // if (post != null) {
    //   return (
    //     currentSocialSet?.channels.filter((x) => x.id == post.channel.id) ?? []
    //   );
    // }

    if (preselectedChannelIds?.length) {
      return (
        currentSocialSet?.channels.filter((x) =>
          preselectedChannelIds.includes(x.id)
        ) ?? []
      );
    }

    if (currentSocialSet?.channels.length == 1) {
      return currentSocialSet?.channels ?? [];
    }

    return [];
    // return currentSocialSet?.channels;
  }

  getPostAttachments(
    postInstance: PostInstance,
    attachmentsMap: Record<string, AttachmentSlot>
  ): AttachmentSlot[] {
    return (
      postInstance?.attachmentSlotIds
        ?.map((x) => attachmentsMap[x])
        ?.filter((x) => !!x) ?? []
    );
  }

  async getUpdatedPostInstanceMap(
    postInstanceMap: Record<string, PostInstance>,
    attachmentsMap: Record<string, AttachmentSlot>,
    post?: Post,
    clonedPost?: Post,
    channels?: Channel[]
  ): Promise<Record<string, PostInstance>> {
    // const generateAttachmentSlots = async (
    //   post?: Post
    // ): Promise<AttachmentSlot[]> => {
    //   let attachmentSlots = [];

    //   if (post?.postAttachments.length) {
    //     if (postInstanceMap[post.channel.id]?.attachmentSlotIds?.length) {
    //       attachmentSlots = this.getPostAttachments(postInstanceMap[post.channel.id], attachmentsMap);
    //     } else {
    //     const slotPromises = post.postAttachments
    //       .map((x) => x.attachment)
    //       .map((attachment) =>
    //         this.getSlotFromAttachment(
    //           attachment,
    //           postInstanceMap[post.channel.id]
    //         )
    //       );

    //     attachmentSlots = await Promise.all(slotPromises);
    //     }
    //   }

    //   return attachmentSlots;
    // };

    let updatedInstanceMap = null;

    if (post) {
      updatedInstanceMap = (() => {
        const postInstance = {
          channel: postInstanceMap[post.channel.id]?.channel ?? post.channel,
          post: post,
          postConfig:
            postInstanceMap[post.channel.id]?.postConfig ?? post.config,
          attachmentSlotIds:
            postInstanceMap[post.channel.id]?.attachmentSlotIds ??
            post.postAttachments
              ?.map((x) => x.attachment?.id)
              ?.filter((x) => !!x) ??
            [],
          title: postInstanceMap[post.channel.id]?.title ?? post.title,
          touched: postInstanceMap[post.channel.id]?.touched ?? false,
          validation: {
            errors: [],
            isValid: true,
          },
        } as PostInstance;

        postInstance.validation = this.validatePostInstance(
          postInstance,
          attachmentsMap
        );

        return {
          [post.channel.id]: postInstance,
        } as Record<string, PostInstance>;
      })();
    } else if (clonedPost) {
      updatedInstanceMap = channels.reduce((accumulator, current: Channel) => {
        const isClonedChannel = current.id === clonedPost.channel.id;

        const postConfig = isClonedChannel
          ? clonedPost.config
          : postInstanceMap[current.id]?.postConfig ??
            getDefaultPostConfig(current.type);

        const attachmentSlotIds = isClonedChannel
          ? clonedPost.postAttachments
              ?.map((x) => x.attachment?.id)
              ?.filter((x) => !!x) ?? []
          : postInstanceMap[current.id]?.attachmentSlotIds ?? [];

        const title = isClonedChannel
          ? clonedPost.title
          : postInstanceMap[current.id]?.title;

        const touched = postInstanceMap[current.id]?.touched ?? false;

        const postInstance = {
          channel: postInstanceMap[current.id]?.channel ?? current,
          postConfig: postConfig,
          attachmentSlotIds: attachmentSlotIds,
          title: title,
          touched: touched,
          validation: postInstanceMap[current.id]?.validation ?? {
            errors: [],
            isValid: true,
          },
        } as PostInstance;

        postInstance.validation = this.validatePostInstance(
          postInstance,
          attachmentsMap
        );

        accumulator[current.id] = postInstance;

        return accumulator;
      }, {} as Record<string, PostInstance>);
    } else {
      updatedInstanceMap = channels.reduce((accumulator, current: Channel) => {
        const postInstance = {
          channel: postInstanceMap[current.id]?.channel ?? current,
          postConfig:
            postInstanceMap[current.id]?.postConfig ??
            getDefaultPostConfig(current.type),
          // attachmentSlots: postInstanceMap[current.id]?.attachmentSlots ?? [],
          attachmentSlotIds:
            postInstanceMap[current.id]?.attachmentSlotIds ?? [],
          title: postInstanceMap[current.id]?.title,
          touched: postInstanceMap[current.id]?.touched ?? false,
          validation: postInstanceMap[current.id]?.validation ?? {
            errors: [],
            isValid: true,
          },
        } as PostInstance;

        postInstance.validation = this.validatePostInstance(
          postInstance,
          attachmentsMap
        );

        accumulator[current.id] = postInstance;

        return accumulator;
      }, {} as Record<string, PostInstance>);
    }

    return updatedInstanceMap;
  }

  async getSlotFromAttachment(
    attachment: Attachment,
    postInstance?: PostInstance
  ): Promise<AttachmentSlot> {
    const isPhoto = attachment.type == AttachmentType.Photo;
    const isVideo = attachment.type == AttachmentType.Video;
    const isDocument = attachment.type == AttachmentType.Document;

    return {
      id: attachment.id,
      attachment: attachment,
      status: "ready",
      isPhoto: isPhoto,
      isVideo: isVideo,
      isDocument: isDocument,
      isFilePreview: false,
      channelType: postInstance?.channel?.type,
      preview: {
        url: isVideo
          ? attachment.info?.url
          : attachment.thumbnails?.medium?.url ?? attachment.info.url,
        width: attachment.info.width,
        height: attachment.info.height,
        fileName: attachment.info.fileName,
        fileSize: attachment.info.fileSize,
        duration: attachment.info.duration,
        resolution: attachment.info.resolution,
        aspectRatio: attachment.info.aspectRatio,
        mimeType: attachment.info.mimeType,
        s3Bucket: attachment.info.s3Bucket,
        s3Key: attachment.info.s3Key,
      },
      thumbnailPreview: {
        url: attachment.thumbnails?.medium?.url,
        width: attachment.thumbnails?.medium?.width,
        height: attachment.thumbnails?.medium?.height,
        fileName: attachment.thumbnails?.medium?.fileName,
        fileSize: attachment.thumbnails?.medium?.fileSize,
        duration: attachment.thumbnails?.medium?.duration,
        resolution: attachment.thumbnails?.medium?.resolution,
        aspectRatio: attachment.thumbnails?.medium?.aspectRatio,
        mimeType: attachment.thumbnails?.medium?.mimeType,
        s3Bucket: attachment.thumbnails?.medium?.s3Bucket,
        s3Key: attachment.thumbnails?.medium?.s3Key,
      },
      file: null,
      postInstance: postInstance,
    } as AttachmentSlot;
  }

  validatePostInstance(
    postInstance: PostInstance,
    attachmentsMap: Record<string, AttachmentSlot>
  ): ValidationResult {
    const attachments = this.getPostAttachments(postInstance, attachmentsMap);

    switch (postInstance.channel.type) {
      case "Facebook":
        return validateFacebookPost(postInstance, attachments);

      case "Instagram":
        return validateInstagramPost(postInstance, attachments);

      case "Twitter":
        return validateTwitterPost(postInstance, attachments);

      case "Pinterest":
        return validatePinterestPost(postInstance, attachments);

      case "YouTube":
        return validateYouTubePost(postInstance, attachments);

      case "TikTok":
        return validateTikTokPost(postInstance, attachments);

      default:
        return {
          errors: [],
          canPreview: true,
          isValid: true,
        };
    }
  }

  getNextPostInstance(
    updatedInstanceMap: Record<string, PostInstance>,
    currentPostInstanceId: string
  ): PostInstance {
    const keys = Object.keys(updatedInstanceMap).filter(
      (x) => x != currentPostInstanceId
    );
    const openedInstance = updatedInstanceMap[keys[keys.length - 1]];

    return openedInstance;
  }

  getSlotFromFile = async (
    file: File,
    channelType?: ChannelType
  ): Promise<AttachmentSlot> => {
    const isPhoto = file.type.startsWith("image");
    const isVideo = file.type.startsWith("video");
    const isDocument = !isPhoto && !isVideo;

    const { preview, thumbnailPreview } = isPhoto
      ? await attachmentService.preProcessImage(file)
      : await attachmentService.preProcessVideo(file);

    const slot: AttachmentSlot = {
      // id: uuidv4().substring(0, 8),
      id: `${uuidv4().substring(0, 8)}-${file.name}}`,
      attachment: null,
      status: "readyToUpload",
      isPhoto: isPhoto,
      isVideo: isVideo,
      isDocument: isDocument,
      preview: preview,
      thumbnailPreview: thumbnailPreview,
      isFilePreview: true,
      file: file,
      channelType: channelType,
    };

    return slot;
  };

  clonePostInstance(
    attachmentsMap: Record<string, AttachmentSlot>,
    sourcePostInstance: PostInstance,
    destinationPostInstance: PostInstance
  ): PostInstance {
    if (sourcePostInstance) {
      const updatedPostInstance: PostInstance = {
        ...destinationPostInstance,
        title: sourcePostInstance.title,
        attachmentSlotIds: [...(sourcePostInstance?.attachmentSlotIds ?? [])],
        touched: false,
        postConfig: this.copyPostConfigFromSource(
          sourcePostInstance,
          destinationPostInstance
        ),
      };

      updatedPostInstance.validation = this.validatePostInstance(
        updatedPostInstance,
        attachmentsMap
      );

      return updatedPostInstance;
    }
  }

  copyPostConfigFromSource(
    sourcePostInstance: PostInstance,
    destinationPostInstance: PostInstance
  ): PostConfig {
    const postConfig: PostConfig = {
      ...destinationPostInstance.postConfig,
    };

    if (!destinationPostInstance?.postConfig?.channelType) {
      return postConfig;
    }

    if (destinationPostInstance.postConfig.channelType == "Facebook") {
      postConfig.facebook = {
        contentOptions: {
          postType:
            sourcePostInstance.postConfig.facebook?.contentOptions?.postType ??
            "Post",
          systemPostType:
            sourcePostInstance.postConfig.facebook?.contentOptions
              ?.systemPostType ?? "TextPost",
          message:
            // sourcePostInstance.postConfig.facebook?.contentOptions?.message ??
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            sourcePostInstance.title,
          link: sourcePostInstance.postConfig.facebook?.contentOptions?.link,
        },
      };
    } else if (destinationPostInstance.postConfig.channelType == "Instagram") {
      postConfig.instagram = {
        contentOptions: {
          postType:
            sourcePostInstance.postConfig.instagram?.contentOptions?.postType ??
            "Post",
          systemPostType:
            sourcePostInstance.postConfig.instagram?.contentOptions
              ?.systemPostType ?? "ImagePost",
          caption:
            // sourcePostInstance.postConfig.instagram?.contentOptions?.caption ??
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            sourcePostInstance.title,
        },
      };
    } else if (destinationPostInstance.postConfig.channelType == "Pinterest") {
      postConfig.pinterest = {
        contentOptions: {
          systemPostType:
            sourcePostInstance.postConfig.pinterest?.contentOptions
              ?.systemPostType ?? "ImagePin",
          title: sourcePostInstance.postConfig.pinterest?.contentOptions?.title,
          link: sourcePostInstance.postConfig.pinterest?.contentOptions?.link,
          description:
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            sourcePostInstance.title,
          boardId: null,
        },
      };
    } else if (destinationPostInstance.postConfig.channelType == "Twitter") {
      postConfig.twitter = {
        contentOptions: {
          systemPostType:
            sourcePostInstance.postConfig.twitter?.contentOptions
              ?.systemPostType ?? "TextPost",
          superFollowers:
            sourcePostInstance.postConfig.twitter?.contentOptions
              ?.superFollowers ?? false,
          caption:
            // sourcePostInstance.postConfig.twitter?.contentOptions?.caption ??
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            sourcePostInstance.title,
        },
      };
    } else if (destinationPostInstance.postConfig.channelType == "YouTube") {
      postConfig.youTube = {
        contentOptions: {
          systemPostType:
            sourcePostInstance.postConfig.youTube?.contentOptions
              ?.systemPostType ?? "ShortVideo",
          postType:
            sourcePostInstance.postConfig.youTube?.contentOptions?.postType ??
            "ShortVideo",
          title: sourcePostInstance.postConfig.youTube?.contentOptions?.title,
          description:
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            sourcePostInstance.title,
          categoryId:
            sourcePostInstance.postConfig.youTube?.contentOptions?.categoryId ??
            "22",
          embeddable:
            sourcePostInstance.postConfig.youTube?.contentOptions?.embeddable ??
            true,
          license:
            sourcePostInstance.postConfig.youTube?.contentOptions?.license ??
            "youtube",
          privacyStatus:
            sourcePostInstance.postConfig.youTube?.contentOptions
              ?.privacyStatus ?? "public",
          madeForKids:
            sourcePostInstance.postConfig.youTube?.contentOptions
              ?.madeForKids ?? false,
          notifySubscribers:
            sourcePostInstance.postConfig.youTube?.contentOptions
              ?.notifySubscribers ?? true,
          tags:
            sourcePostInstance.postConfig.youTube?.contentOptions?.tags ?? [],
        },
      };
    } else if (destinationPostInstance.postConfig.channelType == "TikTok") {
      postConfig.tikTok = {
        contentOptions: {
          postType:
            sourcePostInstance.postConfig.tikTok?.contentOptions?.postType ??
            "Video",
          systemPostType:
            sourcePostInstance.postConfig.tikTok?.contentOptions
              ?.systemPostType ?? "Video",
          title:
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.title ??
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            sourcePostInstance.title,
          description:
            // sourcePostInstance.postConfig.tikTok?.contentOptions?.description ??
            // sourcePostInstance.postConfig.pinterest?.contentOptions?.description ??
            // sourcePostInstance.postConfig.youTube?.contentOptions?.description ??
            sourcePostInstance.title,
          privacyStatus:
            sourcePostInstance.postConfig.tikTok?.contentOptions
              ?.privacyStatus ?? null,
          comment:
            sourcePostInstance.postConfig.tikTok?.contentOptions?.comment ??
            false,
          duet:
            sourcePostInstance.postConfig.tikTok?.contentOptions?.duet ?? false,
          stitch:
            sourcePostInstance.postConfig.tikTok?.contentOptions?.stitch ??
            false,
          commercialContent:
            sourcePostInstance.postConfig.tikTok?.contentOptions
              ?.commercialContent ?? false,
          promotionalContent:
            sourcePostInstance.postConfig.tikTok?.contentOptions
              ?.promotionalContent ?? false,
          paidPartnership:
            sourcePostInstance.postConfig.tikTok?.contentOptions
              ?.paidPartnership ?? false,
          aiGenerated:
            sourcePostInstance.postConfig.tikTok?.contentOptions?.aiGenerated ??
            false,
          autoAddMusic:
            sourcePostInstance.postConfig.tikTok?.contentOptions
              ?.autoAddMusic ?? false,
        },
      };
    }

    return postConfig;
  }
}

export default new CreatePostHelper();
