import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

export default function FacebookInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <p>
        To connect a Facebook Page to your Viraly account, you need to log in
        with a <span className="font-medium">personal Facebook profile</span>{" "}
        that has <span className="font-medium">admin access to the Page</span>{" "}
        you're trying to link. More information on Facebook Page roles and the
        functionality of Facebook Business Suite can be found{" "}
        <a
          className="text-primary-600 font-medium inline-flex gap-1 items-center"
          href="https://viraly.io/docs/troubleshooting-facebook-and-instagram-connections#checking-admin-settings"
          target="_blank"
        >
          <div className="flex gap-1 items-center">
            <ArrowTopRightOnSquareIcon className="w-[10px] h-[10px]" /> here
          </div>
        </a>
      </p>
      <p className="mt-5">
        When connecting your Facebook pages to Viraly, it’s{" "}
        <strong className="font-medium">critical</strong> to allow{" "}
        <strong className="font-medium">all permissions for all pages</strong>{" "}
        during the process, even for those you don't intend to connect. If any
        permissions are not granted, we might be unable to detect the accounts
        you manage, which will stop us from connecting them to Viraly.
      </p>
    </div>
  );
}
