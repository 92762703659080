import clsx from "clsx";
import Spinner from "../../../../../components/common/Spinner";
import { CalendarEvent } from "../../models/calendar-event";
import { Square2StackIcon } from "@heroicons/react/24/solid";
import {
  ExclamationCircleIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { AttachmentType } from "../../../../../models/entities/attachment";
import { splitPascalCase } from "../../../../../utils/shared-utils";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { findChannelConfig } from "../../../../../data/channels-config";
import PostPreviewTile from "./PostPreviewTile";
import dayjs from "dayjs";
import useCurrentSocialSet from "../../../../../hooks/useCurrentSocialSet";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useRef } from "react";

export interface CalendarTileV2Props {
  event: CalendarEvent;
  loading?: boolean;
  showTitle: boolean;
  isSubmitting: boolean;
  onDelete: (event: CalendarEvent) => void;
  onEdit: (event: CalendarEvent) => void;
  onClone: (event: CalendarEvent) => void;
  onPublishNow: (event: CalendarEvent) => void;
  onMoveToDraft: (event: CalendarEvent) => void;
  onMoveToScheduled: (event: CalendarEvent) => void;
}

const editableStatuses = [
  "Draft",
  "Scheduled",
  "Processed",
  "ProcessingFailed",
  "PublishFailed",
];

const enterTimeoutDuration = 200;
const leaveTimeoutDuration = 350;

export default function CalendarTileV2({
  event,
  loading,
  showTitle,
  isSubmitting,
  onDelete,
  onEdit,
  onClone,
  onPublishNow,
  onMoveToDraft,
  onMoveToScheduled,
}: CalendarTileV2Props) {
  const socialSet = useCurrentSocialSet();

  const triggerRef = useRef(null);
  const enterTimeOutRef = useRef(null);
  const exitTimeoutRef = useRef(null);

  const handleEnter = (isOpen) => {
    clearTimeout(exitTimeoutRef.current);
    clearTimeout(enterTimeOutRef.current);

    enterTimeOutRef.current = setTimeout(() => {
      !isOpen && triggerRef.current?.click();
    }, enterTimeoutDuration);
  };

  const handleLeave = (isOpen: boolean) => {
    clearTimeout(exitTimeoutRef.current);
    clearTimeout(enterTimeOutRef.current);

    exitTimeoutRef.current = setTimeout(() => {
      isOpen &&
        document.activeElement === triggerRef.current &&
        triggerRef.current?.click();
    }, leaveTimeoutDuration);
  };

  return (
    <Popover>
      {({ open }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
          className={clsx(
            "group/inner p-1 relative flex flex-col h-full border rounded-md shadow-sm text-white",
            event.post.status == "Scheduled" && "bg-slate-100 border-slate-200",
            event.post.status == "Draft" && "bg-slate-100 border-slate-200",
            event.post.status == "Processed" && "bg-slate-100 border-slate-200",
            event.post.status == "Published" && "bg-slate-100 border-slate-200",
            (event.post.status == "ProcessingFailed" ||
              event.post.status == "PublishFailed") &&
              "bg-slate-100 border-slate-200",
            (event.post.status == "ProcessingEnqueued" ||
              event.post.status == "Processing" ||
              event.post.status == "PublishingEnqueued" ||
              event.post.status == "Publishing") &&
              "bg-slate-100 border-slate-200",
            !loading && editableStatuses.includes(event.post.status)
              ? "cursor-pointer"
              : "cursor-default"
          )}
        >
          <PopoverButton ref={triggerRef}></PopoverButton>

          <div
            className="flex flex-row flex-wrap gap-1 items-center"
            onClick={
              !editableStatuses.includes(event.post.status)
                ? null
                : (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEdit(event);
                    return false;
                  }
            }
          >
            <div className="flex-1 flex items-center gap-1">
              <img
                referrerPolicy="no-referrer"
                className="h-5 w-5 flex-none rounded-full bg-gray-50"
                src={findChannelConfig(event.post.channel.type).imageUrl}
              />
              <time
                dateTime={event.datetime}
                className={clsx(
                  "text-slate-700 leading-normal truncate",
                  !loading ? "group-hover/inner:text-primary-600" : ""
                )}
              >
                {dayjs(event.datetime).tz(socialSet.timezone).format("hh:mm A")}
              </time>
            </div>
            {/* {event.post.status == "Draft" ? (
              <div className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-orange-200/50 text-orange-700 ring-orange-700/10">
                Draft
              </div>
            ) : (
              <time
                dateTime={event.datetime}
                className={clsx(
                  "hidden text-slate-700 md:block leading-normal truncate",
                  !loading ? "group-hover/inner:text-primary-600" : ""
                )}
              >
                {event.time}
              </time>
            )} */}

            {event.post.postAttachments.length > 0 && (
              <div className="ml-auto relative rounded-md bg-white shadow-md overflow-hidden aspect-1">
                {event.post.postAttachments[0].attachment.type ==
                AttachmentType.Photo ? (
                  <>
                    <img
                      className="h-6 w-6 flex-none object-cover"
                      src={
                        event.post.postAttachments[0].attachment.thumbnails
                          ?.small?.url ??
                        event.post.postAttachments[0].attachment.info.url
                      }
                    />
                    {event.post.postAttachments.length > 1 ? (
                      <div className="absolute left-0 right-0 top-0 bottom-0 flex items-end pl-0.5 pb-0.5 justify-start bg-black/30">
                        <Square2StackIcon className="w-4" />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <img
                      className="h-8 w-8 flex-none object-cover"
                      src={
                        event.post.postAttachments[0].attachment.thumbnails
                          ?.small?.url
                      }
                    />
                    <div className="absolute left-0 right-0 top-0 bottom-0 flex items-end pl-0.5 pb-0.5 justify-start bg-black/30">
                      <PlayCircleIcon className="w-4" />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          {showTitle ? (
            <div className="leading-4 text-slate-700 font-normal font-sm mt-1 line-clamp-1">
              {event.post.title}
            </div>
          ) : null}

          {(event.post.status == "ProcessingFailed" ||
            event.post.status == "PublishFailed") && (
            <div className="mt-2 flex items-center rounded-md gap-1 px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-200/50 text-red-700 ring-red-700/10">
              <ExclamationCircleIcon className="h-4 w-4 aspect-1 text-red-500" />
              <div className="flex-1 truncate">
                {splitPascalCase(event.post.status)}
              </div>
            </div>
          )}

          {event.post.status == "Draft" && (
            <div className="mt-2 flex items-center rounded-md gap-1 px-2 py-1 text-xs font-medium ring-1 ring-inset bg-orange-200/50 text-orange-700 ring-orange-700/10">
              <DocumentTextIcon className="h-4 w-4 aspect-1 text-red-500" />
              <div className="flex-1 truncate">
                {splitPascalCase(event.post.status)}
              </div>
            </div>
          )}

          {loading && (
            <div className="ml-auto absolute w-full h-full left-0 top-0 right-0 bottom-0 flex justify-center rounded-md bg-gray-800/30">
              <Spinner size="5" />
            </div>
          )}

          {/* Popover */}
          {!loading && (
            <PopoverPanel
              transition
              anchor="right"
              className="w-80 z-10 divide-y divide-white/5 rounded-xl shadow-lg ring-1 ring-black/5 bg-white/5 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
            >
              <PostPreviewTile
                event={event}
                flat={false}
                isSubmitting={isSubmitting}
                onDelete={onDelete}
                onEdit={onEdit}
                onClone={onClone}
                publishNow={onPublishNow}
                moveToDraft={onMoveToDraft}
                moveToScheduled={onMoveToScheduled}
              />
            </PopoverPanel>
          )}
        </div>
      )}
    </Popover>
  );
}
