/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import AttachmentSlot from "../models/attachment-slot";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import attachmentService from "../../../../services/api/attachment-service";
import storageService from "../../../../services/storage/storage-service";
import errorReporter from "../../../../utils/error-reporter";
import { openDefaultEditor, setPlugins } from "@pqina/pintura";
import { plugin_trim } from "@pqina/pintura-video";
import {
  ExclamationCircleIcon,
  PaintBrushIcon,
  PlayCircleIcon,
  TrashIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/20/solid";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { AttachmentType } from "../../../../models/entities/attachment";

import "@pqina/pintura/pintura.css";
import "@pqina/pintura-video/pinturavideo.css";
import VideoThumb from "./VideoThumb";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "../models/validation-result";
import getDefaultEditorOptions from "../../../../data/pintura-editor-options";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";

setPlugins(plugin_trim);

export interface AttachmentProps {
  id: string;
  slot: AttachmentSlot;
  validationErrors: ValidationError[];
  onSlotUpdated: (slot: AttachmentSlot) => void;
  onSlotDeleted: (slot: AttachmentSlot) => void;
}

export default function Attachment({
  slot,
  id,
  validationErrors,
  onSlotUpdated,
  onSlotDeleted,
}: AttachmentProps) {
  const currentSocialSet = useCurrentSocialSet();
  const [progressText, setProgressText] = useState<string>("");
  const thumbGenRef = useRef();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    let progressText = "";

    if (slot.status == "uploading") {
      progressText = !slot.progress ? "Starting" : ` ${slot.progress}%`;
    } else if (slot.status == "processing") {
      progressText = "Finalizing";
    } else if (slot.status == "error") {
      progressText = "Error";
    }

    setProgressText(progressText);
  }, [slot.status, slot.progress]);

  useEffect(() => {
    if (slot.status == "readyToUpload") {
      uploadFile();
    }
  }, [slot.status]);

  const uploadFile = async () => {
    const file: File = slot.file;

    slot.status = "uploading";
    onSlotUpdated(slot);

    if (file) {
      try {
        slot.progress = 0;
        onSlotUpdated(slot);

        const { url, metadata, attachment } =
          await attachmentService.getUploadUrl({
            socialSetId: currentSocialSet.id,
            fileName: file.name,
            fileSize: file.size,
            mimeType: file.type,
            role: "PostAttachment",
          });

        await storageService.uploadFileToS3(url, metadata, file, (progress) => {
          slot.progress = progress;
          onSlotUpdated(slot);
        });

        slot.status = "processing";
        onSlotUpdated(slot);

        const processedAttachment =
          attachment.type == AttachmentType.Photo
            ? await attachmentService.processPhoto(attachment, file)
            : await attachmentService.processVideo(attachment, file);

        slot.attachment = processedAttachment;
        slot.status = "ready";
        slot.progress = null;

        onSlotUpdated(slot);
      } catch (e) {
        slot.status = "error";
        slot.progress = null;

        onSlotUpdated(slot);

        errorReporter.alertErrors(e);
      }
    }
  };

  const onEditImage = (slot: AttachmentSlot) => {
    const imageFile =
      slot.pintura?.file ?? slot.file ?? slot.attachment.info.url;
    const imageState = slot.pintura?.data ?? {};

    const imageEditorOptions = getDefaultEditorOptions([slot.channelType]);

    imageEditorOptions.imageState = imageState;
    imageEditorOptions.src = imageFile;

    // (imageEditorOptions as any).trimWillRenderControls = (controls, env, redraw) => {
    //   // we add the video thumbnail button
    //   controls.push([
    //     "Button",
    //     "video-thumbnail",
    //     {
    //       label: "Create thumbnail",
    //       onclick: () => {
    //         // we remember timestamp for this thumbnail so we can create full size thumbnail when done
    //         editor.imageMetadata = {
    //           thumbnail: editor.imageCurrentTime,
    //         };

    //         // we generate a temporary thumbnail for display purposes
    //         createThumbnail(editor).then((newThumbnailURL) => {
    //           // if already generated a thumbnail, revoke the URL so we free up memory
    //           if (currentThumbnailURL) {
    //             URL.revokeObjectURL(currentThumbnailURL);
    //             currentThumbnailURL = undefined;
    //           }

    //           // set new thumbnail
    //           currentThumbnailURL = newThumbnailURL;

    //           // triggers redraw of the trim util
    //           redraw();
    //         });
    //       },
    //     },
    //   ]);

    //   // we add the preview thumbnail if it's set
    //   if (currentThumbnailURL) {
    //     controls.push([
    //       "div",
    //       "thumbnail",
    //       {
    //         innerHTML: `<img src="${currentThumbnailURL}" alt=""/>`,
    //       },
    //     ]);
    //   }

    //   return controls;
    // };

    const editor = openDefaultEditor(imageEditorOptions);

    editor.on("process", ({ dest, imageState }) => {
      slot.file = dest;
      slot.pintura = { file: imageFile, data: imageState };

      if (slot.preview.url) URL.revokeObjectURL(slot.preview.url);
      slot.preview.url = URL.createObjectURL(slot.file);

      console.log(slot);

      onSlotUpdated(slot);
      uploadFile();
    });
  };

  return (
    <>
      <div
        className="w-full h-full relative group cursor-move"
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        key={id}
      >
        {/* <div className="absolute left-0 top-0 rounded-md z-20">
          <TrashIcon
            className="cursor-pointer transition-all -ml-2 -mt-2 w-6 text-white border-2 bg-slate-700 hover:w-7 hover:-mt-2.5 hover:-ml-2.5 hover:bg-slate-800 border-white p-1 rounded-full"
            onClick={() => onSlotDeleted(slot)}
          />
        </div> */}

        {validationErrors.length ? (
          <div className="absolute left-0 top-0 z-20">
            <div
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-html={`<ul class="list-disc pl-3">${validationErrors
                .map((e) => `<li>${e.message}</li>`)
                .join("")}</ul>`}
              className="rounded-full w-5 h-5 -ml-2 -mt-2 flex flex-col items-center justify-center bg-white hover:scale-110 transition-all cursor-default"
            >
              <ExclamationCircleIcon className="w-8 h-8 text-yellow-600" />
            </div>
          </div>
        ) : null}

        {slot.status == "uploading" ? (
          <div className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60 z-10">
            <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
              <div className="w-12">
                <CircularProgressbar
                  value={slot.progress}
                  text={progressText}
                  strokeWidth={4}
                  styles={buildStyles({
                    textColor: "#ffffff",
                    pathColor: "#d1d5db",
                    trailColor: "rgb(58, 9, 20)",
                    strokeLinecap: 22,
                  })}
                />
              </div>
            </div>
          </div>
        ) : null}

        {slot.status == "processing" ? (
          <>
            <div
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-content="Processing"
              className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60 z-10"
            >
              <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <div className="w-5">
                    <WrenchScrewdriverIcon className="animate-pulse text-white" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-0 justify-center items-start absolute z-10 left-0 right-0 bottom-0 rounded-b-md transition-all p-px bg-black/15">
              <TrashIcon
                className="cursor-pointer transition-all w-6 text-white hover:bg-black/70 p-1 rounded-full focus:outline-none"
                onClick={() => onSlotDeleted(slot)}
                data-tooltip-id="tooltip-placeholder"
                data-tooltip-content={`Delete ${
                  slot.isVideo ? "video" : "photo"
                }`}
              />
            </div>
          </>
        ) : null}

        {slot.status == "error" ? (
          <div className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60 z-10">
            <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
              <div className="font-medium text-xs leading-3 text-red-200">
                Upload failed
              </div>
              <ArrowPathIcon
                onClick={uploadFile}
                className="w-5 mt-2 text-white hover:text-white/60 cursor-pointer focus:outline-none"
                data-tooltip-id="tooltip-placeholder"
                data-tooltip-content="Retry"
              />
            </div>
          </div>
        ) : null}
        {/* 
        {slot.status == "validationFailed" ? (
          <div className="absolute left-0 right-0 top-0 bottom-0 rounded-md bg-slate-800/60">
            <div className="rounded-md w-full h-full flex flex-col items-center justify-center">
              <TrashIcon
                onClick={uploadFile}
                className="w-5 text-white hover:text-white/60 cursor-pointer focus:outline-none"
                data-tooltip-id="tooltip-placeholder"
                data-tooltip-content="Delete"
              />
            </div>
          </div>
        ) : null} */}

        {(slot.status == "ready" || slot.status == "validationFailed") && (
          <div className="flex flex-row gap-0 justify-center items-start absolute z-10 left-0 right-0 bottom-0 rounded-b-md transition-all p-px bg-black/45 opacity-100">
            <TrashIcon
              className="cursor-pointer transition-all w-6 text-white hover:bg-black/70 p-1 rounded-full focus:outline-none"
              onClick={() => onSlotDeleted(slot)}
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-content={`Delete ${
                slot.isVideo ? "video" : "photo"
              }`}
            />

            <PaintBrushIcon
              className="cursor-pointer transition-all w-6 text-white hover:bg-black/70 p-1 rounded-full focus:outline-none"
              onClick={() => onEditImage(slot)}
              data-tooltip-id="tooltip-placeholder"
              data-tooltip-content={`Edit ${slot.isVideo ? "video" : "photo"}`}
            />
            {/* 
            {slot.isVideo && (
              <PhotoIcon
                data-tooltip-id="tooltip-placeholder"
                data-tooltip-content="Edit thumbnail"
                className="cursor-pointer transition-all w-6 text-white hover:bg-black/70 p-1 rounded-full focus:outline-none"
                onClick={() => generateThumb()}
              />
            )} */}
          </div>
        )}

        {slot.isPhoto ? (
          <>
            <img
              src={slot.preview.url}
              className="aspect-1 w-full h-full object-cover rounded-md shadow-sm shadow-gray-400"
            />
          </>
        ) : (
          <>
            <div className="relative">
              <video
                src={slot.preview.url}
                className="aspect-1 w-full h-full object-cover rounded-md shadow-sm shadow-gray-400"
              ></video>
              <div className="flex items-center justify-center absolute bottom-0 top-0 left-0 right-0">
                <PlayCircleIcon className="w-6 text-white/80" />
              </div>
            </div>
            <VideoThumb
              ref={thumbGenRef}
              src={slot.preview.url}
              width={undefined}
              height={undefined}
            />
          </>
        )}
      </div>
    </>
  );
  // }
}
