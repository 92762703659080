import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import placeholderImage from "../../../../assets/images/viraly-post-preview.png";
import FacebookPostPreview from "./facebook/FacebookPostPreview";
import FacebookReelPreview from "./facebook/FacebookReelPreview";
import FacebookStoryPreview from "./facebook/FacebookStoryPreview";
import clsx from "clsx";
import { PostInstance } from "../models/post-instance";
import InstagramPostPreview from "./instagram/InstagramPostPreview";
import InstagramReelPreview from "./instagram/InstagramReelPreview";
import InstagramStoryPreview from "./instagram/InstagramStoryPreview";
import PinterestPinPreview from "./pinterest/PinterestPinPreview";
import TwitterTweetPreview from "./twitter/TwitterTweetPreview";
import YouTubeShortPreview from "./youTube/YouTubePreview";
import TikTokVideoPreview from "./tikTok/TikTokVideoPreview";
import TikTokPhotoPreview from "./tikTok/TikTokPhotoPreview";
import { useMemo } from "react";
import AttachmentSlot from "../models/attachment-slot";

export interface PostPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  togglePreview: () => void;
}

export default function PostPreview({
  postInstance,
  attachments,
  togglePreview,
}: PostPreviewProps) {
  const previewContent = useMemo(() => {
    let previewContent = null;

    if (!postInstance) {
      return null;
    }

    switch (postInstance.channel.type) {
      case "Facebook":
        if (
          postInstance.postConfig.facebook.contentOptions.postType == "Post"
        ) {
          previewContent = (
            <FacebookPostPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        } else if (
          postInstance.postConfig.facebook.contentOptions.postType == "Reel"
        ) {
          previewContent = (
            <FacebookReelPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        } else if (
          postInstance.postConfig.facebook.contentOptions.postType == "Story"
        ) {
          previewContent = (
            <FacebookStoryPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        }
        break;

      case "Instagram":
        if (
          postInstance.postConfig.instagram.contentOptions.postType == "Post"
        ) {
          previewContent = (
            <InstagramPostPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        } else if (
          postInstance.postConfig.instagram.contentOptions.postType == "Reel"
        ) {
          previewContent = (
            <InstagramReelPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        } else if (
          postInstance.postConfig.instagram.contentOptions.postType == "Story"
        ) {
          previewContent = (
            <InstagramStoryPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        }
        break;

      case "Pinterest":
        previewContent = (
          <PinterestPinPreview
            attachments={attachments}
            postInstance={postInstance}
          />
        );
        break;

      case "Twitter":
        previewContent = (
          <TwitterTweetPreview
            attachments={attachments}
            postInstance={postInstance}
          />
        );
        break;

      case "YouTube":
        if (
          postInstance.postConfig.youTube.contentOptions.postType ==
          "ShortVideo"
        ) {
          previewContent = (
            <YouTubeShortPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        }
        break;

      case "TikTok":
        if (postInstance.postConfig.tikTok.contentOptions.postType == "Video") {
          previewContent = (
            <TikTokVideoPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        } else if (
          postInstance.postConfig.tikTok.contentOptions.postType == "Photo"
        ) {
          previewContent = (
            <TikTokPhotoPreview
              attachments={attachments}
              postInstance={postInstance}
            />
          );
        }
        break;
    }

    return previewContent;
  }, [postInstance, attachments]);

  const placeholderContent = (
    <div className="flex flex-col items-center -mt-12">
      <img className="w-44 opacity-55" src={placeholderImage} />
      <div className="text-gray-400 font-medium text-sm mt-4">
        See your post's preview here
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-row gap-2 px-4 pt-4 items-center">
        <div className="text-lg font-semibold leading-6 text-gray-900">
          {postInstance?.channel?.type ?? "Post"} Preview
        </div>
        <QuestionMarkCircleIcon
          className="w-4 text-slate-400"
          data-tooltip-id="tooltip-placeholder"
          data-tooltip-content="This is an aproximation of how your post will look like once live. You might see some differences across devices."
        />

        {/* <XMarkIcon
          onClick={togglePreview}
          className="md:hidden w-7 h-7 ml-auto bg-white rounded-full text-gray-800 shadow button-shadow p-0.5"
        /> */}
        <span
          onClick={togglePreview}
          className="md:hidden ml-auto text-2xl text-gray-900 font-medium leading-none cursor-pointer hover:text-gray-600"
        >
          ×
        </span>
      </div>
      <div
        className={clsx(
          "flex-1 flex justify-center overflow-auto p-4 w-[20.5rem] mx-auto",
          postInstance?.validation.canPreview ? "items-start" : "items-center"
        )}
      >
        {/* {postInstance.touched ? previewContent : placeholderContent} */}
        {postInstance?.validation.canPreview
          ? previewContent
          : placeholderContent}
      </div>
    </div>
  );
}
