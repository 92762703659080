import dayjs from "dayjs";
import Hashtag from "../../../../models/entities/hashtag";
import { useMemo } from "react";
import clsx from "clsx";

export interface HashtagCardProps {
  hashtag: Hashtag;
  selected: boolean;
  onEdit: (hashtag: Hashtag) => void;
}

export default function HashtagCard({
  hashtag,
  selected,
  onEdit,
}: HashtagCardProps) {
  const tagList = useMemo(
    () =>
      (hashtag?.hashtags ?? "")
        .split(" ")
        .map((tag) => tag.trim())
        .filter((tag) => !!tag),
    [hashtag?.hashtags]
  );

  return (
    <div
      onClick={() => onEdit(hashtag)}
      className={clsx(
        "overflow-hidden bg-white rounded-lg shadow-sm border cursor-pointer hover:opacity-85 hover:bg-gray-50/50",
        selected ? "border-primary-600 ring-1 ring-primary-600" : ""
      )}
    >
      <div className="p-4">
        <div className="flex gap-4 items-center overflow-auto">
          <div className="flex-1 flex flex-col justify-between gap-6">
            <div className="flex items-center gap-2 line-clamp-2">
              <div className="text-lg font-medium text-gray-900 ">
                {hashtag.topic}
              </div>
              <div>
                <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 whitespace-nowrap">
                  {hashtag.hashtags.split(" ").length} hashtags
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 flex-wrap">
                {tagList.map((tag) => (
                  <span
                    key={tag}
                    className="inline-flex gap-2 items-center rounded-md bg-secondary-50 px-2 py-1 text-sm font-medium text-secondary-700 ring-1 ring-inset ring-secondary-700/10"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex justify-end">
              <div className="whitespace-nowrap text-gray-800 text-xs font-medium">
                {dayjs(hashtag.createdAt).format("DD MMM YYYY, hh:mm A")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
