import {
  EllipsisHorizontalIcon,
  GlobeAsiaAustraliaIcon,
} from "@heroicons/react/24/solid";
import {
  ChatBubbleOvalLeftIcon,
  HandThumbUpIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import FacebookVideoFrame from "./FacebookVideoFrame";
import { PostInstance } from "../../models/post-instance";
import AttachmentSlot from "../../models/attachment-slot";
import { convertUrlsToLinks } from "../../../../../utils/text-utils";
import { useMemo } from "react";
import DOMPurify from "dompurify";

export interface FacebookPostPreviewProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
}

export default function FacebookPostPreview({
  postInstance,
  attachments,
}: FacebookPostPreviewProps) {
  const facebookPostConfig = postInstance.postConfig.facebook;
  const channel = postInstance.channel;

  let mediaContent = null;

  if (facebookPostConfig.contentOptions.systemPostType == "VideoPost") {
    mediaContent = getVideoPostContent(attachments);
  } else {
    mediaContent = getPhotoPostContent(attachments);
  }

  function getVideoPostContent(attachments: AttachmentSlot[]) {
    if (!attachments.length) {
      return null;
    }

    return <FacebookVideoFrame slot={attachments[0]} />;
  }

  function getPhotoPostContent(attachments: AttachmentSlot[]) {
    let mediaContent = null;

    attachments = attachments.filter((x) => x.isPhoto);

    if (attachments.length == 1) {
      mediaContent = (
        <div className="grid grid-cols-3 grid-rows-2 max-h-[55vh] h-full">
          <img
            className="col-span-3 row-span-2 w-full h-fit object-cover"
            src={attachments[0].preview.url}
          />
        </div>
      );
    } else if (attachments.length == 2) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];

      mediaContent = (
        <div className="grid grid-cols-6 grid-rows-6 gap-0.5 aspect-[4/3]">
          <img
            className="col-span-3 row-span-6 w-full h-full object-cover"
            src={attachment1.preview.url}
          />
          <img
            className="col-span-3 row-span-6 w-full h-full object-cover"
            src={attachment2.preview.url}
          />
        </div>
      );
    } else if (attachments.length == 3) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];
      const attachment3 = attachments[2];

      if (attachment1.preview.height > attachment1.preview.width) {
        mediaContent = (
          <div className="grid grid-cols-8 grid-rows-10 gap-0.5 aspect-1">
            <img
              className="col-span-4 row-span-10 w-full h-full object-cover"
              src={attachment1.preview.url}
            />
            <img
              className="col-span-4 row-span-5 w-full h-full object-cover"
              src={attachment2.preview.url}
            />
            <img
              className="col-span-4 row-span-5 w-full h-full object-cover"
              src={attachment3.preview.url}
            />
          </div>
        );
      } else {
        mediaContent = (
          <div className="grid grid-cols-8 grid-rows-10 gap-0.5 aspect-1">
            <img
              className="col-span-8 row-span-5 w-full h-full object-cover"
              src={attachment1.preview.url}
            />
            <img
              className="col-span-4 row-span-5 w-full h-full object-cover"
              src={attachment2.preview.url}
            />
            <img
              className="col-span-4 row-span-5 w-full h-full object-cover"
              src={attachment3.preview.url}
            />
          </div>
        );
      }
    } else if (attachments.length == 4) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];
      const attachment3 = attachments[2];
      const attachment4 = attachments[3];
      const hasVertical = attachments.some(
        (x) => x.preview.height > x.preview.width
      );

      if (hasVertical) {
        if (attachment1.preview.height > attachment1.preview.width) {
          mediaContent = (
            <div className="grid grid-cols-9 grid-rows-9 gap-0.5 aspect-1">
              <img
                className="col-span-6 row-span-9 w-full h-full object-cover"
                src={attachment1.preview.url}
              />
              <img
                className="col-span-3 row-span-3 w-full h-full object-cover"
                src={attachment2.preview.url}
              />
              <img
                className="col-span-3 row-span-3 w-full h-full object-cover"
                src={attachment3.preview.url}
              />
              <img
                className="col-span-3 row-span-3 w-full h-full object-cover"
                src={attachment4.preview.url}
              />
            </div>
          );
        } else {
          mediaContent = (
            <div className="grid grid-cols-9 grid-rows-9 gap-0.5 aspect-1">
              <img
                className="col-span-9 row-span-6 w-full h-full object-cover"
                src={attachment1.preview.url}
              />
              <img
                className="col-span-3 row-span-3 w-full h-full object-cover"
                src={attachment2.preview.url}
              />
              <img
                className="col-span-3 row-span-3 w-full h-full object-cover"
                src={attachment3.preview.url}
              />
              <img
                className="col-span-3 row-span-3 w-full h-full object-cover"
                src={attachment4.preview.url}
              />
            </div>
          );
        }
      } else {
        mediaContent = (
          <div className="grid grid-cols-8 grid-rows-8 gap-0.5 aspect-1">
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment1.preview.url}
            />
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment2.preview.url}
            />
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment3.preview.url}
            />
            <img
              className="col-span-4 row-span-4 w-full h-full object-cover"
              src={attachment4.preview.url}
            />
          </div>
        );
      }
    } else if (attachments.length == 5) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];
      const attachment3 = attachments[2];
      const attachment4 = attachments[3];
      const attachment5 = attachments[4];

      mediaContent = (
        <div className="grid grid-cols-6 grid-rows-9 gap-0.5 aspect-1">
          <img
            className="col-span-3 row-span-6 w-full h-full object-cover"
            src={attachment1.preview.url}
          />
          <img
            className="col-span-3 row-span-6 w-full h-full object-cover"
            src={attachment2.preview.url}
          />
          <img
            className="col-span-2 row-span-3 w-full h-full object-cover"
            src={attachment3.preview.url}
          />
          <img
            className="col-span-2 row-span-3 w-full h-full object-cover"
            src={attachment4.preview.url}
          />
          <img
            className="col-span-2 row-span-3 w-full h-full object-cover"
            src={attachment5.preview.url}
          />
        </div>
      );
    } else if (attachments.length > 5) {
      const attachment1 = attachments[0];
      const attachment2 = attachments[1];
      const attachment3 = attachments[2];
      const attachment4 = attachments[3];
      const attachment5 = attachments[4];

      mediaContent = (
        <div className="grid grid-cols-6 grid-rows-9 gap-0.5 aspect-1">
          <img
            className="col-span-3 row-span-6 w-full h-full object-cover"
            src={attachment1.preview.url}
          />
          <img
            className="col-span-3 row-span-6 w-full h-full object-cover"
            src={attachment2.preview.url}
          />
          <img
            className="col-span-2 row-span-3 w-full h-full object-cover"
            src={attachment3.preview.url}
          />
          <img
            className="col-span-2 row-span-3 w-full h-full object-cover"
            src={attachment4.preview.url}
          />
          <div className="col-span-2 row-span-3 w-full h-full relative">
            <img
              className="w-full h-full object-cover"
              src={attachment5.preview.url}
            />
            <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-black/10 text-white text-3xl leading-10 font-semibold">
              +{attachments.length - 4}
            </div>
          </div>
        </div>
      );
    }

    return mediaContent;
  }

  const textFormatted = useMemo(() => {
    return facebookPostConfig.contentOptions?.message
      ? convertUrlsToLinks(
          facebookPostConfig.contentOptions.message,
          "color:#5AA7FF;font-weight:500;"
        )
      : "";
  }, [facebookPostConfig.contentOptions?.message]);

  return (
    <>
      <div className="flex flex-col border-gray-600 ring-1 ring-gray-200 rounded-md w-full">
        <div className="flex flex-row items-center gap-3 px-4 py-3">
          <div>
            <img
              referrerPolicy="no-referrer"
              className="h-10 w-10 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
              src={channel.pictureUrl}
            />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="font-semibold text-[15px] leading-5 text-gray-950">
              {channel.name}
            </div>
            <div className="flex flex-row items-center gap-1">
              <span className="text-[13px] leading-4 text-gray-500 font-normal">
                Just now {" · "}
              </span>
              <GlobeAsiaAustraliaIcon className="w-3.5 text-gray-500" />
            </div>
          </div>
          <div>
            <EllipsisHorizontalIcon className="w-6 text-slate-800" />
          </div>
        </div>

        {textFormatted ? (
          <div className="px-4 pb-3">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(textFormatted),
              }}
              className="text-[15px] font-normal leading-5 text-gray-900 whitespace-pre-wrap overflow-hidden line-clamp-3 break-words"
            ></div>
          </div>
        ) : null}

        <div className="overflow-hidden">{mediaContent}</div>

        <div className="flex flex-row gap-3 justify-around px-4 py-2">
          <div className="flex flex-row gap-1 text-gray-700">
            <HandThumbUpIcon className="w-4" />
            <span className="text-sm font-medium leading-5">Like</span>
          </div>
          <div className="flex flex-row gap-1 text-gray-700">
            <ChatBubbleOvalLeftIcon className="w-4 scale-x-[-1]" />
            <span className="text-sm font-medium leading-5">Comment</span>
          </div>
          <div className="flex flex-row gap-1 text-gray-700">
            <ShareIcon className="w-4" />
            <span className="text-sm font-medium leading-5">Share</span>
          </div>
        </div>
      </div>
    </>
  );
}
