"use client";

import { useState, useEffect, useMemo } from "react";
import {
  Bars4Icon,
  Squares2X2Icon as Squares2X2IconMini,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

import clsx from "clsx";
import queryNames from "../../../queries/query-names";
import AttachmentSlot from "../create-post/models/attachment-slot";
import ViewAttachment from "./components/ViewAttachment";
import { invalidateQuery } from "../../../queries/query-utils";
import { useQuery } from "@tanstack/react-query";
import mediaLibraryService from "../../../services/api/media-library-service";
import createPostHelper from "../../../services/application/create-post-helper";
import Uploader from "./components/Uploader";
import Spinner from "../../../components/common/Spinner";
import EmptyMediaLibrary from "./components/EmptyMediaLibrary";
import FileTypePicker from "./components/FileTypePicker";
import { AttachmentType } from "../../../models/entities/attachment";
import UploadedByPicker from "./components/UploadedByPicker";
import User from "../../../models/entities/user";
import { groupByKeySelector } from "../../../utils/list-utils";
import dayjs from "dayjs";
import ListView from "./components/ListView";
import GridView from "./components/GridView";
import FavoritesToggle, { TabId } from "./components/FavoritesToggle";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";

interface MediaProps {
  isSelectionMode?: boolean;
  onSelected?: (attachments: AttachmentSlot[]) => void;
}

export default function Media({
  isSelectionMode = false,
  onSelected,
}: MediaProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentAttachment, setCurrentAttachment] =
    useState<AttachmentSlot>(null);
  const [isGridView, setIsGridView] = useState(true);
  const [activeTab, setActiveTab] = useState<TabId>("All");
  const [attachmentSlots, setAttachmentSlots] = useState<AttachmentSlot[]>([]);
  const [attachmentType, setAttachmentType] = useState<AttachmentType>(null);
  const [searchQuery, setSearchQuery] = useState<string>(null);
  const [selectedAttachments, setSelectedAttachments] = useState<
    AttachmentSlot[]
  >([]);
  const [uploadedBy, setUploadedBy] = useState<User[]>([]);
  const currentSocialSet = useCurrentSocialSet();

  const { isLoading, data: attachments = [] } = useQuery({
    queryKey: [queryNames.mediaAttachments, currentSocialSet.id],
    queryFn: () => mediaLibraryService.getAttachments(currentSocialSet.id),
  });

  const displayedSlots = useMemo(() => {
    let result = attachmentSlots;

    if (activeTab === "Favouried") {
      result = attachmentSlots.filter(
        (slot) => slot.attachment?.favoriteAt != null
      );
    }

    if (attachmentType) {
      result = result.filter(
        (slot) => slot.attachment?.type === attachmentType
      );
    }

    if (uploadedBy.length) {
      result = result.filter((slot) =>
        uploadedBy.some((user) => user.id === slot.attachment?.uploadedBy.id)
      );
    }

    if (searchQuery) {
      result = result.filter((slot) => {
        return (
          slot.attachment?.info?.fileName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          slot.attachment?.description
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      });
    }

    const sortByField = activeTab === "Favouried" ? "favoriteAt" : "createdAt";

    const normalizeValue = (slot: AttachmentSlot) => {
      return slot.attachment?.[sortByField] ?? new Date();
    };

    result.sort((a, b) => {
      if (normalizeValue(a) > normalizeValue(a)) {
        return -1;
      }
      if (normalizeValue(a) < normalizeValue(b)) {
        return 1;
      }
      return 0;
    });

    return result;
  }, [activeTab, attachmentSlots, attachmentType, searchQuery, uploadedBy]);

  const slotsByDate = useMemo(() => {
    const sortByField = activeTab === "Favouried" ? "favoriteAt" : "createdAt";

    const normalizeValue = (slot: AttachmentSlot) => {
      return slot.attachment?.[sortByField] ?? new Date();
    };

    return groupByKeySelector(displayedSlots, (item) => {
      const dateTime = dayjs(normalizeValue(item));
      const isToday = dayjs().startOf("day").isSame(dateTime.startOf("day"));
      const isYesterday = dayjs()
        .subtract(1, "day")
        .startOf("day")
        .isSame(dateTime.startOf("day"));

      return isToday
        ? "Today"
        : isYesterday
        ? "Yesterday"
        : dateTime.format("MMMM D, YYYY");
    });
  }, [activeTab, displayedSlots]);

  useEffect(() => {
    const process = async () => {
      const slots = await Promise.all(
        (attachments ?? []).map((attachment) =>
          createPostHelper.getSlotFromAttachment(attachment)
        )
      );

      setAttachmentSlots(slots);
    };

    process();
  }, [attachments?.length]); // Keep the 'length' here otherwise if causes endless loop if we only keep 'attachments'. Don't ask me why, I don't know.

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    setCurrentAttachment(null);
  };

  const viewAttachment = (attachmentSlot: AttachmentSlot) => {
    if (attachmentSlot.attachment == null) return;

    if (isSelectionMode) {
      // If in selection mode, return the selected attachment
      let updatedSelection = selectedAttachments ?? [];

      if (updatedSelection.find((x) => x.id == attachmentSlot.id)) {
        updatedSelection = updatedSelection.filter(
          (x) => x.id != attachmentSlot.id
        );
      } else {
        updatedSelection = [...updatedSelection, attachmentSlot];
      }

      setSelectedAttachments(updatedSelection);
      onSelected?.(updatedSelection);
      return;
    } else {
      setCurrentAttachment(attachmentSlot);
      openSidebar();
    }
  };

  const onAttached = (attachmentSlots: AttachmentSlot[]) => {
    setAttachmentSlots((previous) => {
      return [...attachmentSlots, ...previous];
    });
  };

  const invalidateMediaAttachments = () => {
    invalidateQuery([queryNames.mediaAttachments]);
  };

  const onSlotUpdated = (slot: AttachmentSlot) => {
    const updatedSlots = [...attachmentSlots];

    const index = updatedSlots.findIndex((x) => x.id == slot.id);
    updatedSlots[index] = {
      ...slot,
    };

    setAttachmentSlots(updatedSlots);
  };

  const onSlotDeleted = (slot: AttachmentSlot) => {
    const updatedSlots = [...attachmentSlots].filter((x) => slot.id != x.id);
    setAttachmentSlots(updatedSlots);

    setCurrentAttachment(null);
    closeSidebar();

    invalidateMediaAttachments();
  };

  const onAttachmentUpdated = (attachmentSlot: AttachmentSlot) => {
    if (attachmentSlot) {
      onSlotUpdated(attachmentSlot);
      setCurrentAttachment(attachmentSlot);
    }
  };

  return (
    <>
      {/* Main content */}
      <div className="flex flex-1 items-stretch h-full">
        <main className="flex-1 overflow-y-auto">
          <div className="flex flex-col mx-auto h-full">
            {/* Full-width title */}
            {!isSelectionMode && (
              <div className="flex justify-between items-center w-full px-6 py-4">
                <h1 className="text-lg font-semibold leading-7 text-gray-900 w-full">
                  Media Library
                </h1>

                <Uploader onAttached={onAttached} />
              </div>
            )}

            <div className="flex flex-col h-full flex-1 px-6 py-4">
              {isLoading && (
                <div className="h-full flex-1 flex flex-col justify-center ">
                  <Spinner />
                </div>
              )}

              {!isLoading && !attachmentSlots.length && (
                <EmptyMediaLibrary onAttached={onAttached} />
              )}

              {!isLoading && attachmentSlots.length > 0 && (
                <>
                  <div className="mt-2">
                    <div className="flex items-start md:items-end flex-wrap md:flex-nowrap gap-2">
                      {/* Tabs */}
                      <div>
                        <FavoritesToggle
                          activeTab={activeTab}
                          onTabChange={setActiveTab}
                        />
                      </div>

                      {/* Search bar */}
                      <div className="relative md:ml-auto">
                        <div className="flex flex-col gap-1">
                          {/* <label
                              htmlFor="email"
                              className="text-sm font-normal text-gray-700"
                            >
                              Search
                            </label> */}
                          <div className="flex rounded-md shadow-sm">
                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-400"
                                />
                              </div>
                              <input
                                type="search"
                                onInput={(e) =>
                                  setSearchQuery(
                                    (e.target as HTMLInputElement).value
                                  )
                                }
                                placeholder="Type to search media"
                                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-200 focus:ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Uploaded by */}
                      <div className="flex flex-col gap-1">
                        {/* <div className="text-sm font-normal text-gray-700">
                            Uploaded by
                          </div> */}
                        <UploadedByPicker
                          selection={uploadedBy}
                          onChanged={setUploadedBy}
                        />
                      </div>

                      {/* File types */}
                      <div className="flex flex-col gap-1">
                        {/* <div className="text-sm font-normal text-gray-700">
                            File type
                          </div> */}
                        <FileTypePicker
                          attachmentType={attachmentType}
                          onChanged={setAttachmentType}
                        />
                      </div>

                      {/* View */}
                      <div className="flex items-center gap-0.5 rounded-lg bg-gray-100 p-0.5">
                        <button
                          type="button"
                          onClick={() => setIsGridView(true)}
                          className={clsx(
                            isGridView ? "bg-white shadow-sm" : "",
                            "rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none"
                          )}
                        >
                          <Squares2X2IconMini
                            aria-hidden="true"
                            className="h-5 w-5"
                          />
                          <span className="sr-only">Use grid view</span>
                        </button>
                        <button
                          type="button"
                          onClick={() => setIsGridView(false)}
                          className={clsx(
                            !isGridView ? "bg-white shadow-sm" : "",
                            "rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none"
                          )}
                        >
                          <Bars4Icon aria-hidden="true" className="h-5 w-5" />
                          <span className="sr-only">Use list view</span>
                        </button>
                      </div>
                    </div>
                    {/* 
                    {isSearchOpen && (
                      <div className="relative mt-2">
                        <div className="flex items-center bg-white shadow-lg rounded-lg w-full">
                          <input
                            type="text"
                            placeholder="Search..."
                            className="w-full rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary-500"
                          />
                          <button
                            type="button"
                            onClick={() => setIsSearchOpen(false)}
                            className=" text-gray-400 hover:text-gray-600 focus:outline-none"
                          >
                            <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                            <span className="sr-only">Close search</span>
                          </button>
                        </div>
                      </div> 
                    )} */}
                  </div>

                  {/* Gallery */}
                  <section className="my-8 flex-1">
                    {displayedSlots.length > 0 && !isGridView && (
                      <ListView
                        slotsByDate={slotsByDate}
                        isSelectionMode={isSelectionMode}
                        selectedAttachments={selectedAttachments}
                        onSlotUpdated={onSlotUpdated}
                        onViewAttachment={viewAttachment}
                      />
                    )}

                    {displayedSlots.length > 0 && isGridView && (
                      <GridView
                        slotsByDate={slotsByDate}
                        isSelectionMode={isSelectionMode}
                        selectedAttachments={selectedAttachments}
                        onSlotUpdated={onSlotUpdated}
                        onViewAttachment={viewAttachment}
                      />
                    )}

                    {displayedSlots.length === 0 && (
                      <div className="flex flex-col justify-center items-center h-full mt-5">
                        <div className="text-gray-800 text-md font-medium">
                          No results found.
                        </div>

                        <div className="text-gray-500 text-sm text-center max-w-lg">
                          Looks like nothing matches your filters. Try adjusting
                          them or clearing the filters to explore more content!
                        </div>
                      </div>
                    )}
                  </section>
                </>
              )}
            </div>
          </div>
        </main>

        {/* Sidebar Modal */}
        {currentAttachment && (
          <ViewAttachment
            attachmentSlot={currentAttachment}
            sidebarOpen={sidebarOpen}
            closeSidebar={closeSidebar}
            onSlotDeleted={onSlotDeleted}
            onAttachmentUpdated={onAttachmentUpdated}
          />
        )}
      </div>
    </>
  );
}
